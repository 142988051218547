@media (min-width: 768px) and (max-width: 1159px) {

html, body {
    font-size: 17px;
}

header.header-container.home + .heading-player-area {
    top: 20px;
    position: absolute;
}

.heading-player-area .logo-area {
    padding: 85px 30px;
}

.podcast-date {
    top: 1px;
}

.breadcrumbs {
    font-size: 14px;
}

.schedule-container .subpage-title, .playlist-container .subpage-title {
    font-size: 14px;
}

.radio-playlist.subpage,
header:not(.home) + .heading-player-area .radio-playlist,
header.header-container.home.sticked + .heading-player-area .radio-playlist {
    font-size: 14px;
    padding: 18px 30px;
}

.menu-container.top-menu .top-menu--regular {
    font-size: 17px;
}

.radio-freq {
    padding-left: 30px;
}

.menu-container.top-menu .top-menu--regular ul {
    padding-left: 30px;
}

.menu-container.top-menu .top-menu--regular ul.main-menu li {
    padding-right: 20px;
}

.menu-center {
    font-size: 17px;
}

footer p {
    font-size: 14px;
}

footer {
    padding: 45px 30px 25px;
}

footer h4 {
    font-size: 22px;
}

footer .container-grid .column.pos-5 {
    grid-template-columns: 100%;

}


.footer--logo {
    width: 125px;
    margin-bottom: 20px;
}


.social-menu {
    padding-right: 30px;
}


.podcasts-section, .news-section, .programs-section, .people-section, .results-section, .schedule-section, .info-section, .playlist-section {
    padding: 10px 30px 50px;
}

.custom-page-section .breadcrumbs {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.custom-page-section {
    padding-bottom: 50px;
}

header:not(.home) + .heading-player-area + .main-content-container .podcasts-section {
    padding: 10px 30px 70px;
}

header:not(.home) + .heading-player-area .radio-playlist .played-now .played-song,
header:not(.home) + .heading-player-area .radio-playlist .played-before .played-song,
header:not(.home) + .heading-player-area .radio-playlist .played-audition .played-song,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now .played-song,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before .played-song,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition .played-song {
    width: 15.359375VW;
    max-width: 15.359375VW;
}

header:not(.home) + .heading-player-area .played-now .rotation-song,
header:not(.home) + .heading-player-area .played-before .rotation-song,
header:not(.home) + .heading-player-area .played-audition .rotation-song,
header.header-container.home.sticked + .heading-player-area .played-now .rotation-song,
header.header-container.home.sticked + .heading-player-area .played-before .rotation-song,
header.header-container.home.sticked + .heading-player-area .played-audition .rotation-song {
/*    max-width: 235px;
    max-width: 18.359375VW;*/
}

.podcasts-section h2 {
    width: 95%;
}

.podcast-item h3 {
    font-size: 17px;
    line-height: 22px;
}

.podcast--tags, .podcast--category, .podcast-date {
    font-size: 12px;
    padding: 0 5px;
    height: 20px;
}

.program-list-container, .people-list-container {
    width: 100%;
}

.program-list, .people-list {
    padding: 0;
}

.navigation-filter {
    font-size: 14px;
    padding-left: 0;
}

.navigation-filter li {
    padding-left: 15px;
}


.content-header.with-filter {
    grid-template-columns: 35% 65%;
}

.playlist-section .content-header.with-filter {
    grid-template-columns: 45% 65%;
}

.select-day-heading {
    left: 80px;
    font-size: 14px;
}

.react-datepicker__input-container input {
    font-size: 17px;
}

.select-day-container {
    right: 212px;
    top: -83px;
}

.schedule-background, .playlist-background {
    width: calc(100% - 97px);
    top: 122px;
}

.schedule-background .dynamic-boxes.bottom-left, .playlist-background .dynamic-boxes.bottom-left {
    bottom: 122px;
}

.schedule-background .dynamic-boxes, .playlist-background .dynamic-boxes {
    width: 145px;
}

.schedule-background .dynamic-boxes.top-right .box-2, .playlist-background .dynamic-boxes.top-right .box-2 {
    width: 56%;
}

.podcasts-section h2, .programs-section h2, .news-section h2, .cathedras-section h2, .results-section h2, .programs-section .pagination li, .programs-section .pagination li.active, .people-section .pagination li, .people-section .pagination li.active, .news-section .pagination li, .news-section .pagination li.active, .podcasts-section .pagination li, .podcasts .pagination li.active, h2.subpage-title, .program-list li h3, .people-list li h3, .people-single-list h3, .main-page-menu ul, .program-description h2, .people-single-container .people-description h2 {
    font-size: 43px;
}

.cathedras-section h2 {
    font-size: 16px;
}

.news-single-slide h3 {
    font-size: 43px;
}

.slide-description-days, .slide-description-hours, .slide-description-text {
    font-size: 17px;
}

.home-slider-section {
    padding: 100px 30px 50px;
    min-height: auto;
    min-height: 526px;
}

.slick-dots {
    bottom: -20px;
}

.slick-prev {
    left: -10px;
}

.slick-next {
    right: -10px;
}

.slick-prev, .slick-next {
    width: 15px;
    height: 15px;
}

.slick-prev:before, .slick-next:before {
    top: -40px;
}

.page-prev-button a, .slick-prev:before {
    background-size: 15px 30px;
    width: 15px;
    height: 30px;
}

.page-next-button a, .slick-next:before {
    background-size: 15px 30px;
    width: 15px;
    height: 30px;
}


.news-single-slide {
    display: grid;
    grid-template-columns: 3.3fr 3fr;
    grid-gap: 20px;
}


.home-wrap .podcasts-section, .home-wrap .news-section, .home-wrap .cathedras-section {
    padding: 55px 30px;
}

.news-section h2 {
    margin-bottom: 65px;
}

.news-section h2 {
    margin-bottom: 60px;
}

.podcast-list {
    grid-gap: 25px;
    max-width: 100%;
}

.podcast--tags, .podcast--category {
    font-size: 14px;
    padding: 0 5px;
    height: auto;
    margin-bottom: 5px;
}

.news--image {
    padding-bottom: 30px;
    max-width: 100%;
}

.news-list {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    grid-gap: 45px 30px;
}

.news--title {
    font-size: 17px;
    line-height: 22px;
}

.search-bar input[type="text"] {
    width: 160px;
}

.results-section h2 {
    width: 95%;
    margin-bottom: 60px;
}

.results--title {
    font-size: 19px;
}

.podcasts-single-element {
    grid-template-columns: 1fr 1.4fr;
    grid-gap: 30px;
}

.program-single-container, .people-single-container {
    margin: 60px 0 0 0;
}

.program-list, .people-list {
    margin: 60px 0 0;
}

.main-page-menu-column {
    grid-template-columns: 40% 50%;
    width: 82%;
    grid-gap: 80px;
}

.info-container h3 {
    font-size: 43px;
}

.alterlator-section h3 {
    font-size: 30px;
    margin-bottom: 30px;
}

.info-background {
    width: calc(100vw - 80px);
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 30px;
    top: 122px;
}

.info-description {
    left: 290px;
    width: 50vw;
    padding-top: 30px;
}


.info-background .dynamic-boxes.bottom-right {
    bottom: 122px;
}

.info-background .dynamic-boxes {
    width: 150px;
    height: 420px;
}

.contact-section {
    padding: 10px 30px 50px;
}


}

















@media (max-width: 767px) {

.podcasts-section, .news-section, .cathedras-section {
    padding: 110px 40px 40px;
}

.podcasts-section h2, .programs-section h2, .news-section h2, .cathedras-section h2 {
    font-size: 27px;
    width: 100%;
}

.cathedras-section h2 {
    font-size: 16px;
}

.home-wrap .podcasts-section h2, .home-wrap .news-section h2  {
    margin-bottom: 45px;
}


.podcasts-section .content-header h2 {
    margin-bottom: 20px;
}

.programs-section .content-header h2 {
    margin-bottom: 20px;
}

.main-content-container > .news-section h2  {
    margin-bottom: 45px;
}

.news-section .modal-header h2 {
    margin-bottom: 10px;
}

.modal .modal-dialog {
    top: 25%;
}


.news-section {
    padding: 110px 40px 40px;
}

.radio-playlist, .radio-playlist.subpage .playlist-area, .played-now, .played-before, .played-audition, .news-single-slide, .podcast-list, .news-list, .cathedras-list {
	display: block;
}

.home-slider-section {
    min-height: auto;
}

.home-slider-section .news-single-slide {
    display: block !important;
}

.slide-description-days, .slide-description-hours, .slide-description-text {
    font-size: 16px;
}

.slide-audition-logo {
    margin-top: 30px;
}

.podcast-list, .news-list {
    width: 100vw;
    margin: 0;
    max-width: 100%;
}


.podcast-list .podcast-item img {
    width: 300px;
    max-width: 100%;
}



html.locked header.header-container.home {
    /*position: fixed;*/
    /*margin-top: 0;*/
}


html header.header-container.home {
}


html header.header-container.home.sticked {
    position: fixed;
    margin-top: 0;
    transition: all 0.5s ease-in-out;
}


.main-container.sticked {
	padding-top: 290px;
}




footer {
    min-height: 50vh;
    padding: 40px 20px 30px;
}

footer .social-menu {
    margin: 0px 0;
    text-align: right;
    width: 100%;
    float: right;
    max-width: 158px;
}

footer .social-menu li {
    display: inline-block;
    width: 26px;
    padding-left: 0;
    box-sizing: content-box;
    padding: 0px 0 15px 15px;
    text-align: right;
}

footer .container-grid .column.pos-5 {
    padding-top: 20px;
    display: block;
}


footer .container-grid .column.pos-6 {
    align-self: start;
    font-size: 16px;
    line-height: 1.5;
    padding-top: 40px;
}


footer .bottom-menu {
    display: block;
    margin: 0;
    padding-left: 0;
    column-count: 2;
    column-gap: 20px;
}

footer .bottom-menu li {
    display: inline-block;
    padding-right: 15px;
    font-size: 17px;
    line-height: 1.2;
    padding-bottom: 15px;
    min-width: 100%;
}


footer h4 {
    font-size: 18px;
    margin-bottom: 10px;
    width: 50%;
}


footer .container-grid {
    display: grid;
}


footer .container-grid .column.pos-1 {
    grid-area: 1 / 1 / 1 / 6;
}

footer .container-grid .column.pos-2a {
    grid-area: 2 / 1 / 2 / 6;
    margin-top: 30px;
}


footer .container-grid .column.pos-2b {
    grid-area: 3 / 1 / 3 / 6;
}

footer .container-grid .column.pos-3 {
    grid-area: 4 / 1 / 4 / 6;
}

footer .container-grid .column.pos-4 {
    grid-area: 1 / 2 / 1 / 6;
}

footer .container-grid .column.pos-5 {
    grid-area: 7 / 1 / 7 / 6;
}

footer .container-grid .column.pos-6 {
    grid-area: 6 / 1 / 6 / 6;
}


.footer--logo img {
    margin-top: 0;
    max-width: 50%;
    width: 125px;
    margin-bottom: 16px;
}





footer .container-grid .column.pos-2a h4 {
    font-size: 27px;
    width: 75%;
}

footer .address {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 500;
    line-height: 1.2;
}

footer h5 {
    text-transform: none;
    font-weight: 400;
    margin-bottom: 0;
    font-size: 17px;
}

footer p a {
    font-weight: 500;
    display: inline-block;
}



/* MENU */



.menu-container.top-menu .top-menu--regular {
    font-size: 17px;
}

.menu-center {
    font-size: 17px;
}


.radio-freq {
    display: inline-block;
    padding-left: 20px;
    width: auto;
}


.menu-container.top-menu.closed {
	overflow: hidden;
}

.menu-container.top-menu.open {
	overflow: visible;
}



.menu-container.top-menu .top-menu--regular ul.main-menu {
    display: none;
}

.menu-container.top-menu .top-menu--regular ul {
    margin: 0;
    display: block;
    padding-left: 0;
    text-align: right;
    padding-right: 0px;
}

.main-page-menu-column {
    display: block;
    grid-template-columns: 40% 50%;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    grid-gap: 80px;
	display: flex;
    flex-direction: column;

}


.menu-group-right {
	display: grid;
    grid-template-columns: 60% 40%;
    padding: 10px 20px;
}

.search-bar input[type="text"] {
	/* TEMPORARY SOLUTION */
    /*width: 14.5vw;*/
    width: 6.2em;
    flex: 1;
}


.menu-column--contact {
	order: 2;
    display: none;
}

.main-content-container .menu-column--contact {
    display: block;
}

.main-content-container .subpage-title + .contact-data {
    max-height: none;
}


.main-content-container .contact-section {
    padding: 10px 20px 40px;
    background-color: #f43f0a;
    min-height: 80vh;
    opacity: 1;
}


.radio-playlist {
    display: grid !important;
    grid-template-columns: 33fr 66fr;
    padding: 40px 0;
}



html, body {
    font-size: 14px;
}

.main-page-menu ul {
    list-style: none;
    font-size: 27px;
    line-height: 1.08;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;
    margin: 0;
    margin-top: 5px;
}

.main-page-menu ul li {
    margin-bottom: 10px;
}

.main-page-menu {
    min-height: calc(100vh - 40px);
    height: auto;
    padding: 8px 20px 20px;
}




html.locked, html.locked body {
    overflow: hidden;
    /*max-height: 100vh;*/
}


.main-page-menu {
    overflow-y: scroll;
    height: 80vh;
}



h2.subpage-title {
    font-size: 27px;
    line-height: 1.08;
    font-weight: 500;
    padding-bottom: 0;
    margin-bottom: 0;


}


.main-page-menu-column .subpage-title {
    cursor: pointer;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #1e00d4;
}


.subpage-title + .contact-data {
	max-height: 0;
	overflow: hidden;
	transition: all 1s ease-in-out;
}

.subpage-title + .contact-data.active {
	max-height: 300vh;
	overflow: hidden;
	transition: all 1s ease-in-out;
	padding-bottom: 80px;
}



.contact-data p {
	font-size: 17px;
}


.menu-column--contact h5 {
    text-transform: none;
    margin-bottom: 0;
    color: #1e00d4;
    font-size: 17px;
    margin-top: 10px;
}






.menu-mobile-items {
    display: grid;
    grid-template-columns: 32% 68%;
    grid-template-columns: 67% 33%;
    margin-bottom: 20px;
}


.menu-mobile-items .search-bar-container {
    background-color: #f43f0a;
    background: transparent;
    position: absolute;
    /* width: 44vw; */
    width: 100%;
}


.menu-mobile-items .search-bar {
    position: relative;
}

.menu-mobile-items .search-bar-container button img {
    position: relative;
    top: 0px;
    width: 21.2px;
}

.menu-mobile-items .search-bar-container form {
    width: 100%;
    display: flex;
    position: absolute;
}

.search-bar-container button.active + .search-input-area {
    display: flex;
    flex: 1;
    align-items: center;
}


.search-bar input[type="image"] {
    top: 0;
}


.menu-mobile-items .social-menu img {
	min-width: 20.48px;
	max-width: 20.48px;
}

.menu-mobile-items ul.social-menu li {
    margin-bottom: 10px;
    display: inline-block;
    margin-left: 10px;
}

.menu-mobile-items .social-menu {
    padding-right: 0;
    text-align: right;
    width: 100%;
}



.results-section h2 {
    font-size: 27px;
    width: 100%;
}








.menu-container.top-menu .top-menu--regular {
	display: block;
}


.heading-player-area .logo-area {
    padding: 40px 20px;
}


.main-page-menu {
    max-height: none;
}




/*.marquee {
  height: 25px;
  width: 420px;

  overflow: hidden;
  position: relative;
}*/





.radio-playlist {
    grid-template-columns: 1fr 15fr;
    margin: 0 20px;
    font-size: 14px;
    overflow: hidden;
    padding: 18px 0 !important;
}

header.header-container.home.sticked + .heading-player-area .radio-playlist {
    grid-template-columns: 1fr 15fr;
    margin: 0 20px;
    overflow: hidden;
    padding: 18px 0 !important;
}


header.home + .heading-player-area .radio-playlist {
    /*margin: 18px 0px;	*/
    margin: 0;
}



.played-title, .played-song {
    display: inline-grid !important;
    align-self: center;
    padding-right: 10px;
}



 .audition-playlist-url {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: right;
}

header.home + .heading-player-area .audition-playlist-url {
	display: block;
    position: absolute;
    top: 50px;
    right: 0px;
    text-align: right;
}



header:not(.home) + .heading-player-area .audition-playlist-url, header.header-container.home.sticked + .heading-player-area .audition-playlist-url,  header.header-container.home + .heading-player-area .audition-playlist-url {
    display: none;
}



.played-song {
	overflow: hidden;
}


.radio-playlist .played-audition {
    grid-template-columns: auto 2fr !important;
    display: block !important;
}



 .radio-playlist .playlist-area {
    display: block;
    position: relative;
    top: 0;
}


.radio-playlist .played-now, .radio-playlist .played-before,  .radio-playlist .played-audition, header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now, header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before, header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition {
	position: absolute;
    top: calc(50% + 3px);
    transform: translate(0, -50%);
    padding-left: 25px !important;
    min-height: 25px;
    height: 25px;
}



header.home + .heading-player-area .radio-playlist .played-now, header.home + .heading-player-area .radio-playlist .played-before, header.home + .heading-player-area .radio-playlist .played-audition {
	position: absolute;
    top: calc(50% + 3px);
    transform: translate(0, -50%);
    padding-left: 15px !important;
    min-height: 30px;
    height: auto;
}

header.home + .heading-player-area .played-now {
    margin-bottom: 5px;
    margin-top: 0;
}


header.home + .heading-player-area .played-title, header.home + .heading-player-area .played-song {
    display: inline-grid !important;
    align-self: center;
    padding-right: 10px;
    width: 100%;
}

header.header-container.home.sticked + .heading-player-area .played-title, header.header-container.home.sticked + .heading-player-area .played-song {
	width: auto;
}

.playlist-area .played-now {
  animation: playnow 30s linear infinite;
}

.playlist-area .played-now.only {
  animation: playnowOnly 30s linear infinite;
}


.playlist-area .played-before {
  animation: playbefore 30s linear infinite;
}

.playlist-area .played-audition {
  animation: playedaudition 30s linear infinite;
}

.playlist-area .played-audition.only {
  animation: playedauditionOnly 30s linear infinite;
}



.played-song .rotation-song {
	display: block;
    width: max-content;
    height: 30px;
    position: relative;
    overflow: hidden;
    animation: marquee 10s linear infinite;
    top: 0;
}






.news-single-element {
    animation: none;
    opacity: 1;
    transform: none;
}





.news--image {
    padding-bottom: 25px;
    max-width: 100%;
}


.news--datetime {
    font-size: 14px;
    margin: 15px 0 15px;
    font-weight: 500;
}

.news--description p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
}


.news-item {
    padding-bottom: 35px;
}





.cathedras-section p {
    font-size: 17px;
    margin-bottom: 70px;
    font-weight: 500;
    line-height: 1.2;
}


.cathedras-list img {
    width: 210px;
    margin-bottom: 30px;
    text-align: left;
    height: auto;
}






.slide-content {
    padding-left: 0;
    grid-column: 2;
    grid-row: 1 / 2;
    padding-top: 20px;
}





.news-single-slide h3 {
    font-size: 27px;
    margin: 0;
    text-transform: uppercase;
    width: 90%;
    line-height: 1.2;
    margin-bottom: 10px;
    margin-top: 5px;
}

.news-slider {
    background: #f43f0a;
    color: #fff;
    padding: 40px 20px;
    min-height: 88vh;
}


.slide-audition-logo img {
	width: 80px;
}



.main-player-button.home {
    width: 80px;
    height: 102px;
    text-align: left;
}


.home-wrap .podcasts-section, .home-wrap .news-section, .home-wrap .cathedras-section {
	padding: 40px 20px;
}


.slide-description-days, .slide-description-hours {
    display: block;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
}



.content-header.with-filter {
    display: block;
    grid-template-columns: 45% 55%;
}





.podcast-item h3 {
    font-size: 17px;
    line-height: 25px;
    text-transform: none;
}

.podcast--tags, .podcast--category {
    font-size: 14px;
    display: inline-block;
    border: 1px solid #000;
    padding: 0 5px;
    height: 24px;
    margin-right: 5px;
    display: table;
    margin-bottom: 8px;
    font-weight: 500;
}

.podcast-item {
    padding-bottom: 25px;
}

.podcast-more--url, .news-more--url {
    font-size: 17px;
}



.podcasts-section, .news-section, .programs-section, .people-section, .results-section, .schedule-section, .info-section, .playlist-section {
    padding: 10px 20px 60px;
}

.custom-page-section .breadcrumbs {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.custom-page-section {
    padding-bottom: 60px;
}

.schedule-list li .category-container {
    display: none;
}


.schedule-list li a {
    display: grid;
    grid-template-columns: 25% 75% 0%;
}

.schedule-list li .hours-container {
    display: inline-block;
    grid-template-columns: auto auto auto 50%;
    text-align: center;
    letter-spacing: 0.1em;
    grid-area: auto;
    text-align: left;
}


.schedule--title {
    grid-area: auto;
}


.schedule-section .content-header.with-filter {
    display: block;
    grid-template-columns: 60% 40%;
    z-index: 1;
    position: relative;
}

.schedule-background {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    right: 0;
    top: 135px;
}

.schedule-background .dynamic-boxes {
    width: 30%;
    height: 500px;
}

.schedule-list li {
    padding-bottom: 5px;
}

.schedule-section .navigation-filter, .playlist-section .navigation-filter {
    margin-top: 50px;
    text-align: left;
/*    padding: 0;*/
}


.programs-info-container {
    margin: 0;
    margin-top: 25px;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
}


.programs-info-row {
    display: block;
    margin-bottom: 90px;
}















header:not(.home) + .heading-player-area + .main-content-container .podcasts-section {
    padding: 10px 20px 40px;
}








.schedule-background, .playlist-background {
    width: 100%;
}



.playlist-item {
    padding-bottom: 3px;
    display: grid;
    grid-template-columns: 21% 89%;
    color: #fff;
}

.schedule-list, .playlist-list {
    width: 52%;
    margin: 0 auto;
    margin-top: 55px;
    max-width: 100%;
    width: 100%;
    margin: 0px;
    padding: 0;
    min-height: 50vh;
}

.playlist-background {
    top: 135px;
}

.playlist-item {
    width: 95%;
}


.playlist-container {
    padding: 70px 0 100px 0;
    min-height: 80vh;
}

.song_container {
	display: block;
}

















.info-section .navigation-filter {
    padding: 0 40px;
    margin: 25px 0 0;
    position: relative;
}




.info-description {
    position: relative;
    left: 0;
    width: 100%;
    font-weight: 400;
    padding-top: 75px;
    padding: 20px;
}



.info-background {
    background: #f43f0a;
    width: 100vw;
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 165px;
    display: none;
}


.info-background .dynamic-boxes {
    display: grid;
    grid-gap: 0;
    position: absolute;
    width: 30%;
    background: transparent;
    top: 0px;
    left: 0;
    align-self: end;
    height: 500px;
}


.info-container h3 {
    font-size: 27px;
}

.alterlator-section h3 {
    font-size: 20px;
    margin-bottom: 25px;
}

.info-description {
    padding: 0;
    font-size: 17px;
    background-color: #f43f0a;
    margin: 0 -20px;
    width: 100vw;
    padding: 20px;
}

.custom-page-section, .info-section, .playlist-section {
    overflow: hidden;
    min-height: auto;
}

.main-container {
    min-height: auto;
}


.program-list-container, .people-list-container {
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
}


.program-list li h3, .people-list li h3, .people-single-list h3 {
    list-style: none;
    font-size: 24px;
    line-height: 1.08;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;
    margin: 0;
}


.program-list li, .people-list li {
    padding-bottom: 20px;
    display: block;
    grid-template-columns: 45% 53%;
    grid-gap: 0 2%;
}

.program-list, .people-list {
    list-style: none;
    margin: 15px 0 0;
    letter-spacing: 0.01em;
    padding: 0;
}

.people-list li:hover h3+.people-short-description, .program-list li:hover h3+.program-short-description {
    opacity: 0;
    display: none;
}

.people-single-list {
    margin-top: 35px;
}

.people-single-list .people-description {
    margin-top: 15px;
}


.program-single-container, .people-single-container {
    margin: 10px 0 0 0;
    display: block;
    grid-template-columns: 36.2% 51.3%;
    grid-gap: 12.5%;
}

.program-description {
	font-size: 17px;
}

.program-image, .people-single-container .people-image {
    text-align: center;
    padding-top: 0px;
}


.program-description h2, .people-single-container .people-description h2 {
    font-size: 27px;
    line-height: 1.08;
    font-weight: 500;
    padding-bottom: 0;
    margin-bottom: 20px;
}

.program-image img, .people-single-container .people-image img {
    text-align: center;
    margin-bottom: 40px;
    margin-left: 0;
}

.program-image img, .people-single-container .people-image img {
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
}


.people-list .people-short-description {
    display: none;
    width: 100px;
}

.podcasts-single-element {
	display: block;
}


.podcasts-single .podcast-description {
	margin-top: 30px;
}

.podcast-source {
    margin-top: 30px;
}




.news--full-description {
    line-height: 1.3157;
}


header + .heading-player-area .main-player-button {
	transition: all 0.5s ease-in-out;
}


header.smaller + .heading-player-area .main-player-button {
	transition: all 0.5s ease-in-out;
}


header.smaller + .heading-player-area .main-player-button, header.smaller.header-container.home.sticked + .heading-player-area .main-player-button.home {
    width: 20px;
    height: 35px;
}








.dynamic-boxes {
    grid-template-columns: 20% 40% 40%;
}

.dynamic-boxes.active .box-2, .dynamic-boxes.is-visible .box-2 {
    height: 21px;
}

.dynamic-boxes.active .box-3, .dynamic-boxes.is-visible .box-3 {
    height: 42px;
}



/* HEADER FIXED VERSION */


html header.header-container.home {
    z-index: 6;
    /*transition: all 0.5s ease-in-out;*/
    position: fixed;
    margin-top: 0;
}

html header.header-container.home + .heading-player-area {
    z-index: 4;
    position: fixed;
    margin-top: 0px;
}

header.home + .heading-player-area .main-player-button {
	transition: none;
}


header.header-container.home.sticked + .heading-player-area .main-player-button img:after {
	background: url(Assets/img/arrow_menu_white.svg) center center no-repeat;
	content: "";
	display: block;
	position: absolute;
}


header.header-container.home.sticked + .heading-player-area {
    border-bottom: 1px solid #f33f0b;
    position: fixed;
    background-color: #1e00d4;
    width: 100%;
}


.header-container.home + .heading-player-area + .main-content-container {
	position: relative;
    margin-top: 495px;
    z-index: 5;
}

html.locked header.header-container.home, html header.header-container.home.sticked {
    z-index: 99;
}

html header.header-container.home.sticked + .heading-player-area {
    z-index: 98;
}





/* DATE PICKER */

.playlist-section .navigation-filter {
    display: none;
}

.playlist-background {
    top: 185px;
}



.playlist-container {
    padding: 150px 0 100px 0;
}

.select-day-container {
    position: relative;
    right: auto;
    top: -120px;
    text-align: left;
}

.select-day-heading {
    left: 0;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: auto;
    left: 0;
}




.react-datepicker__month-container {
    min-width: auto;
    min-width: calc(100vw - 40px);
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 0.126rem 0.616rem;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 23px;
    transform: translate3d(0, 26px, 0px) !important;
    width: 100%;
}


.react-datepicker__input-container input {
    max-width: 150px;
    padding-right: 10px;
}


.banner-container {
    top: -20px;
}


/* ALTERLATOR */

fieldset p {
    display: flex;
    align-items: baseline;
}

fieldset input[type="radio"] {
    min-width: 18px;
}

#submit-info.disabled {
    bottom: 0;
    top: auto;
    width: 100%;
}

}


@media (max-width: 959px) {


.navigation-filter {
    display: inline-block;
    text-align: left;
    width: 100%;
    position: relative;
    min-height: 32px;
}

.navigation-filter li {
    display: block;
    margin: 0;
    padding: 0;
}


.navigation-filter .dropdown-button {
    background: url(Assets/img/arrow_menu_white.svg) center center no-repeat;
    width: 25px;
    height: 13px;
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    transition: 0.5s ease-in-out;
    transform: rotate(180deg);
    cursor: pointer;
}

.navigation-filter.active .dropdown-button {
    transform: rotate(0deg);
}

.navigation-filter .select-category {
    display: block;
    position: absolute;
    overflow: hidden;
}


.navigation-filter li a, .navigation-filter.active .select-category.hidden, .navigation-filter.active .select-category, .navigation-filter .select-category {
    display: block;
    opacity: 0;
    padding-left: 0px;
    padding-bottom: 0px;
    height: 0px;
    transition: all 0.5s ease-in-out;
}

.navigation-filter.active li a, .navigation-filter .select-category.active {
    padding-bottom: 11px;
    display: block;
    opacity: 1;
    height: 32px;
    transition: all 0.5s ease-in-out;
}

.schedule-section .navigation-filter .select-category {
    display: none;
}


.navigation-filter li a.active {
    display: block;
    opacity: 1;
    height: 32px;
    transition: all 0.5s ease-in-out;
}



.navigation-filter li {
    z-index: 2;
    position: relative;
    background-color: #1e00d4;
}

.programs-section .navigation-filter li {
    background-color: #1e00d4;
}

.podcasts-section .navigation-filter li {
    background-color: #fff;
    background-color: #fd4a4a;
}

.schedule-section .navigation-filter li {
    background: transparent;
}

 .podcasts-section .podcasts-single-element h2 {
    margin-bottom: 20px;
}


.schedule-section .navigation-filter a.active {
    color: #1e00d4;
}

.schedule-container .subpage-title {
    display: none;
}

.podcasts-section .navigation-filter .dropdown-button {
    filter: invert(1);
}


.player-description {
    margin-top: 35px;
    max-width: 100%;
    width: 100%;
    padding-right: 40px;
}

.player-view .breadcrumbs ul li.breadcrumb-active a {
    color: #1e00d4;
}

.player-view .playlist-background {
    top: 0px;
}

.playlist-background .dynamic-boxes.bottom-left {
    bottom: 0px;
}

.player-view .playlist-container {
    padding: 0px 0 70px 0;
}

.player-view .playlist-background .dynamic-boxes {
    width: 105px;
    height: 500px;
}



/* FB VIDEO FIX */


iframe[src*="facebook.com/plugins/video"], iframe[src*="youtube.com"] {
    height: calc(56vw - 20px);
    max-height: calc(56vw - 20px);

}




}


/* TABLET PORTRAIT */

@media (min-width: 768px) and (max-width: 959px) {

.content-header.with-filter {
        grid-template-columns: 70% 30%;
}

.schedule-section .content-header.with-filter {
    grid-template-columns: 70% 30%;
}

.schedule-section .navigation-filter a.active {
    color: #f43f0a;
}


.playlist-section .content-header.with-filter {
    grid-template-columns: 60% 40%;
}

.select-day-container {
    right: 98px;
    top: -83px;
}

.schedule-list, .playlist-list {
    width: 100%;
    margin: 0 auto;
    margin-top: 55px;
    max-width: 83%;
}


.programs-info-row {
    display: grid;
    grid-template-columns: 50% 50%;
}

.programs-info-container {
    margin-top: 0px;
    max-width: 100%;
    min-width: 85%;
    width: 85%;
    margin-bottom: 120px;
}


.schedule-section .navigation-filter, .playlist-section .navigation-filter {
    margin-top: 0px;
    text-align: left;
    background-color: #1e00d4;
    position: relative;
    bottom: -30px;
    min-height: 40px;
    max-height: 40px;
    transition: all 0.5s ease-in-out;
    padding-bottom: 10px;
    right: -40px;
}

.navigation-filter.active {
    max-height: 800px;
}

.navigation-filter .select-category.active {
    margin-left: 40px;
}

.navigation-filter li {
    margin-left: 40px;
}

.program-list li, .people-list li {
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 50% 48%;
    grid-gap: 0 2%;
}

header:not(.home) + .heading-player-area .radio-playlist .played-before, header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before {
    /* display: none; */
    width: 0%;
    opacity: 0;
}

header:not(.home) + .heading-player-area .radio-playlist .played-now .played-song, header:not(.home) + .heading-player-area .radio-playlist .played-before .played-song, header:not(.home) + .heading-player-area .radio-playlist .played-audition .played-song, header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now .played-song, header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before .played-song, header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition .played-song {
    width: 23.359375VW;
    max-width: 23.359375VW;
}

.radio-playlist.subpage .playlist-area, header:not(.home) + .heading-player-area .radio-playlist .playlist-area, header.header-container.home.sticked + .heading-player-area .radio-playlist .playlist-area {
    width: calc(100vw - 152px);
    grid-template-columns: 40% 0 60%;
}

.main-page-menu-column {
    grid-template-columns: 45% 50%;
    width: 100%;
    grid-gap: 5%;
}

.menu-container.top-menu .top-menu--regular ul.main-menu li {
    display: none;
}


.info-section .navigation-filter li a {
    display: block;
    opacity: 1;
    padding-left: 0px;
    padding-bottom: 0px;
    height: auto;
    transition: all 0.5s ease-in-out;
}

.info-section .navigation-filter .dropdown-button {
    display: none;
}

.info-section .navigation-filter {
    width: auto;
    padding: 0px 20px 20px 0;
    margin: 75px 0 0;
    position: absolute;
    background-color: #1e00d4;
}

.info-background .dynamic-boxes.bottom-right {
    display: none;
}


}








@media (min-width: 960px) {

/*html.locked  header:not(.home) .radio-playlist {
    margin-right: 17px;
}


html.locked .top-menu--regular .social-menu {
    margin-right: 17px;
}

html.locked header.home + .heading-player-area .logo-area {
    margin-right: 17px;
}*/


html.locked .main-page-menu-column {
/*    width: 70vw;
    margin: 0 auto;
    grid-gap: 80px;*/
/*    position: relative;
    right: 0px;*/
}


}






/* ANIMATIONS */


@keyframes marquee {
  0% { transform: translateX(100%) }
  45% { transform: translateX(0%) }
  100% { transform: translateX(-100%) }
}




@keyframes playnow {
  0% { display: none;
    opacity: 0;  z-index: 0; }
  2% { display: block; opacity: 1; z-index: 2; }
  31% { opacity: 1; z-index: 2; }
  33% { display: none; opacity: 0;  z-index: 0; }
  100% { display: none; opacity: 0;  z-index: 0; }
}



@keyframes playnowOnly {
  0% { display: none;
    opacity: 0;  z-index: 0; }
  2% { display: block; opacity: 1; z-index: 2; }
  48% { opacity: 1; z-index: 2; }
  50% { display: none; opacity: 0;  z-index: 0; }
  100% { display: none; opacity: 0;  z-index: 0; }
}


@keyframes playbefore {
  0% { display: none;
    opacity: 0; z-index: 0; }
  33% { display: none;
    opacity: 0;  z-index: 0; }
  35% { display: block; opacity: 1;; z-index: 2; }
  64% { opacity: 1;; z-index: 2; }
  66% { display: none; opacity: 0; }
  100% { display: none; opacity: 0; }
}


@keyframes playedaudition {
  0% { display: none;
    opacity: 0;  z-index: 0; }
  68% { display: none;
    opacity: 0;  z-index: 0; }
  70% { display: block; opacity: 1;; z-index: 2; }
  96% { opacity: 1; z-index: 2; }
  98% { display: none; opacity: 0; z-index: 0; }
  100% { display: none; opacity: 0; z-index: 0; }
}

@keyframes playedauditionOnly {
  0% { display: none;
    opacity: 0;  z-index: 0; }
  52% { display: none;
    opacity: 0;  z-index: 0; }
  54% { display: block; opacity: 1;; z-index: 2; }
  96% { opacity: 1; z-index: 2; }
  98% { display: none; opacity: 0; z-index: 0; }
  100% { display: none; opacity: 0; z-index: 0; }
}




/* VOLUME */


@media (max-width: 959px) {

.volume-container {
    display: none !important;
}


}


/* SUMMARY */


@media (max-width: 959px) {

    .vote-list.summary {
        margin: 0 -10px;
    }

    .summary fieldset {
        border: 0px solid rgb(255 255 255 / 0.25);
        padding: 0px 0px;
        margin-bottom: 10px;
    }


    .summary.columns-3 {
        display: grid;
        grid-template-columns: 1fr 45px 60px;
        grid-gap: 10px;
        align-items: center;
    }

    .summary.columns-3 label {
        font-size: 13px;
    }

    .summary.columns-3 audio {
        display: inline-block;
        max-width: 45px;
        height: 40px;
    }

    .summary.columns-3 #submit-vote {
        border: 1px solid #1e00d4;
        padding: 6px 5px;
        font-size: 12px;
        width: 100%;
    }



}