/* GLOBAL */

html,
body {
    background: #1e00d4;
    color: #fff;
    font-family: "Atlas", sans-serif;
    font-size: 19px;
}

html.locked {
    overflow: hidden;
}

.ssr-data {
    display: none;
}

iframe,
img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    text-transform: none;
}

p {
    margin-top: 0;
}

img {
    max-width: 100%;
}

a {
    color: #f43f0a;
    text-decoration: none;
}

a:hover {
    opacity: 0.7;
}

nav a {
    color: #1e00d4;
}

.bottom-menu a {
    color: #fff;
}

.bg-color1 {
    background: #1e00d4;
    color: #fff;
}

.bg-color2 {
    background: #f43f0a;
    color: #fff;
}

.bg-color3 {
    background: #fff;
    color: #000;
}

.bg-color4 {
    background: #fe4a49;
    color: #000;
}

.text-color-orange {
    color: #fe9605;
}

.news--full-description {
    line-height: 25px;
}

.main-container {
    min-height: 80vh;
}

/* HEADER - TOP NAV */

#root {
    margin-top: 141px;
}

header {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    background-color: #1e00d4;
}

header + .heading-player-area {
    background-color: #1e00d4;
    position: fixed;
    top: 40px;
    z-index: 998;
    width: 100%;
}

header.header-container.home + .heading-player-area {
    top: 40px;
    position: absolute;
}

header.header-container.home.sticked + .heading-player-area {
    position: fixed;
    top: 40px;
}

.radio-freq {
    display: inline-block;
    padding-left: 35px;
}

.menu-container.top-menu .top-menu--regular {
    background: #f43f0a;
    color: #1e00d4;
    height: 40px;
    font-size: 19px;
    line-height: 40px;
    font-weight: 500;
    display: flex;
    z-index: 9999;
    position: relative;
    overflow: hidden;
}

.search-bar,
.social-menu {
    display: inline-block;
}



.social-menu {
    padding-right: 35px;
    text-align: right;
    width: 100%;
}

.menu-container.top-menu .top-menu--regular ul {
    margin: 0;
    display: inline-block;
    padding-left: 50px;
}

.menu-container.top-menu .top-menu--regular ul.main-menu {
    flex: 2;
}

.menu-group-right {
    flex: 0.53 1;
    display: inline-flex;
    min-width: 240px;
}

.menu-container.top-menu .top-menu--regular ul li {
    display: inline;
}

.menu-container.top-menu .top-menu--regular ul.main-menu li {
    padding-right: 35px;
}

.menu-container.top-menu .top-menu--regular ul.social-menu li {
    margin-left: 5px;
}

@media (max-width: 1080px) {
    ul.main-menu li:nth-child(3) {
        /*display: none !important;   */
    }
}

@media (max-width: 959px) {
    ul.main-menu li:nth-child(3) {
        display: none !important;
    }
}

/* HEADER SEARCH */

.search-bar {
    position: absolute;
}

.search-bar input {
    background: transparent;
    border: 0;
    outline: none;
    color: #1e00d4;
    height: 40px;
    font-size: 19px;
    line-height: 40px;
    font-weight: 500;
    font-family: inherit;
}

.search-bar input[type="text"] {
    font-size: 16px;
    font-weight: 400;
    position: relative;
    bottom: 1px;
}

/* also works for firefox! */
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
    filter: none; /* u need this for firefox */
    -webkit-text-fill-color: unset;
    -webkit-box-shadow: 0 0 0px 40rem rgb(249 122 121) inset;
}



.search-bar input[type="submit"],
.search-bar input[type="image"] {
    cursor: pointer;
}

.search-bar input[type="image"] {
    height: 20px;
    position: relative;
    top: 4px;
}

.search-bar-container {
    background-color: #f43f0a;
    height: 40px;
    line-height: 37px;
}


.search-bar-container.search-bar-podcasts {
    background-color: #f97a79;
    height: 40px;
    line-height: 37px;
    padding: 5px 15px;
    box-sizing: content-box;
    margin-bottom: 1.5rem;
}


.search-bar-container button {
    background: transparent;
    border: 0;
    outline: none;
    margin: 0;
    padding: 0;
    height: 40px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.search-bar-container button img {
    position: relative;
    top: 2px;
}

.search-bar-container .search-input-area {
    display: inline-block;
    height: 40px;
}

.search-bar-container button.static + .search-input-area {
    width: 0;
    display: inline-block;
    overflow: hidden;
    /*transition: all 0.5s ease-in-out;*/
}

.search-bar-container button.active + .search-input-area {
    display: inline-block;
    margin-left: 10px;
}

.search-bar-container.search-bar-podcasts button + .search-input-area {
    margin-left: 12px;
}

.podcast-list-container .search-bar {
    position: relative;
    display: flex;
}

/* HEADER - LOGO AREA AND PLAYER */

.header-container.home {
    /*    position: relative;
    margin-top: -141px;*/
    position: fixed;
}

.home-logo-1,
.home-logo-2 {
    height: auto;
}

.header-container:not(.home) + .heading-player-area .home-logo-1,
.header-container:not(.home) + .heading-player-area .home-logo-2,
header.header-container.home.sticked + .heading-player-area .home-logo-1,
header.header-container.home.sticked + .heading-player-area .home-logo-2,
header.header-container.home.sticked + .heading-player-area .home-logo-3,
.header-container:not(.home) + .heading-player-area .home-logo-3,
header.header-container.home.sticked + .heading-player-area .banner-container,
.header-container:not(.home) + .heading-player-area .banner-container {
    display: none;
}

.heading-player-area {
    margin: 0 auto;
    text-align: center;
}

.heading-player-area .logo-area {
    margin: 0 auto;
    width: auto;
    display: inline-block;
    text-align: center;
    padding: 85px 0;
}

header:not(.home) + .heading-player-area .logo-area,
header.header-container.home.sticked + .heading-player-area .logo-area {
    padding: 0;
    width: 100%;
}

.logo-area img + img {
    /*margin-top: 225px;	*/
}

.radio-playlist {
    display: grid;
    grid-template-columns: 1fr 5fr;
    padding: 40px 0;
}

.home-sticky-audio-button,
.normal-audio-button {
    height: auto;
}

header.header-container.home + .heading-player-area .main-player-button .home-sticky-audio-button,
header + .heading-player-area .home-sticky-audio-button {
    display: none;
    height: auto;
}

header.header-container.home + .heading-player-area .main-player-button .normal-audio-button {
    display: block;
    height: auto;
}

header.header-container.home.sticked + .heading-player-area .main-player-button .home-sticky-audio-button {
    display: block;
    height: auto;
}

header.header-container.home.sticked + .heading-player-area .main-player-button .normal-audio-button {
    display: none;
    height: auto;
}

/* HEADER STICKY BEHAVIOR */

html header.header-container.home.sticked {
    position: fixed;
    margin-top: 0;
    transition: all 0.5s ease-in-out;
}

.radio-playlist.subpage,
header:not(.home) + .heading-player-area .radio-playlist,
header.header-container.home.sticked + .heading-player-area .radio-playlist {
    grid-template-columns: 1fr 15fr;
    padding: 18px 35px;
    font-size: 16px;
}

.radio-playlist.subpage .playlist-area,
header:not(.home) + .heading-player-area .radio-playlist .playlist-area,
header.header-container.home.sticked + .heading-player-area .radio-playlist .playlist-area {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    text-transform: uppercase;
}

.play-button {
    text-align: left;
}

.playlist-item .play-time {
    letter-spacing: 0.1em;
}

.playlist-item a,
.playlist-item a .song-title {
    color: #fff;
}

.playlist-item .artist,
.playlist-item a .artist {
    color: #1e00d4;
    font-weight: 500;
}

.react-audio-player {
    display: none;
}

.main-player-button {
    border: 0;
    background: transparent;
    padding: 0;
    outline: none;
    width: 50px;
    height: 65px;
    color: #fff;
    cursor: pointer;
}

.main-player-button.home {
    width: 130px;
    height: 165px;
    text-align: left;
}

.header-container.home.sticked + .heading-player-area .main-player-button.home {
    width: 50px;
    height: 65px;
}

.play-button img {
    width: 50px;
}

.radio-playlist.subpage .play-button img {
    width: 50px;
}

.played-now,
.played-before,
.played-audition {
    display: grid;
    grid-template-columns: 1fr 4fr;
    text-align: left;
    color: #f43f0a;
    font-weight: 500;
}

.navigation-filter .select-category {
    display: none;
}

.played-now {
    margin-bottom: 5px;
    margin-top: 10px;
}

.radio-playlist.subpage .played-now,
header:not(.home) + .heading-player-area .played-now,
header.header-container.home.sticked + .heading-player-area .played-now {
    margin-bottom: 0;
    margin-top: 0;
}

.played-before {
    color: #fe9605;
    margin-bottom: 40px;
}

.played-before a {
    color: #fe9605;
}

.played-audition {
    grid-template-columns: 1fr 3fr 1fr;
}

header:not(.home) + .heading-player-area .radio-playlist .played-now,
header:not(.home) + .heading-player-area .radio-playlist .played-before,
header:not(.home) + .heading-player-area .radio-playlist .played-audition,
header:not(.home) + .heading-player-area .radio-playlist .audition-playlist-url,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition,
header.header-container.home.sticked + .heading-player-area .radio-playlist .audition-playlist-url {
    align-self: center;
    margin-bottom: 7px;
    white-space: nowrap;
    padding-left: 40px;
}

.radio-playlist.subpage .played-now {
    padding-left: 20px;
}

header:not(.home) + .heading-player-area .played-now,
header:not(.home) + .heading-player-area .played-before,
header:not(.home) + .heading-player-area .played-audition,
header.header-container.home.sticked + .heading-player-area .played-now,
header.header-container.home.sticked + .heading-player-area .played-before,
header.header-container.home.sticked + .heading-player-area .played-audition {
    display: block;
}

header:not(.home) + .heading-player-area .played-title,
header:not(.home) + .heading-player-area .played-song,
header.header-container.home.sticked + .heading-player-area .played-title,
header.header-container.home.sticked + .heading-player-area .played-song {
    display: inline-block;
    align-self: center;
    padding-right: 10px;
}

header:not(.home) + .heading-player-area .radio-playlist .played-audition,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition {
    grid-template-columns: auto 2fr 1fr;
    display: grid;
}

.audition-playlist-url {
    text-align: right;
}

.audition-playlist-url a {
    font-weight: 500;
    border-bottom: 2px solid #f43f0a;
    padding-bottom: 2px;
}

/* HEADER TOP RWD CORRECTIONS */

@media (min-width: 768px) {
    .main-player-button {
        margin-right: 25px;
    }

    .radio-playlist.subpage .playlist-area,
    header:not(.home) + .heading-player-area .radio-playlist .playlist-area,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .playlist-area {
        grid-template-columns: 30% 30% 40%;
    }

    header:not(.home) + .heading-player-area .radio-playlist .played-now .played-song,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now .played-song {
        padding-right: 0px;
    }

    header:not(.home) + .heading-player-area .radio-playlist .played-now,
    header:not(.home) + .heading-player-area .radio-playlist .played-before,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before {
        grid-template-columns: 60px auto;
        display: grid;
        grid-template-columns: auto 1fr;
        padding-left: 0px;
        padding-right: 20px;
    }

    header:not(.home) + .heading-player-area .radio-playlist .played-audition,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition {
        grid-template-columns: auto 2fr 95px;
        display: grid;
    }

    header:not(.home) + .heading-player-area .radio-playlist .played-audition,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition {
        padding-left: 0px;
        padding-right: 0px;
    }

    header:not(.home) + .heading-player-area .radio-playlist .audition-playlist-url,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .audition-playlist-url {
        padding-left: 0px;
    }

    header:not(.home) + .heading-player-area .rotation-song,
    header.header-container.home.sticked + .heading-player-area .rotation-song {
        display: inline-block;
        white-space: nowrap;
        /*    max-width: 235px;
    width: 18.359375VW;*/
        overflow: hidden;
    }

    header:not(.home) + .heading-player-area .played-now .rotation-song,
    header:not(.home) + .heading-player-area .played-before .rotation-song,
    header:not(.home) + .heading-player-area .played-audition .rotation-song,
    header.header-container.home.sticked + .heading-player-area .played-now .rotation-song,
    header.header-container.home.sticked + .heading-player-area .played-before .rotation-song,
    header.header-container.home.sticked + .heading-player-area .played-audition .rotation-song {
        /*    max-width: 235px;
    max-width: 18.359375VW;*/
    }

    header:not(.home) + .heading-player-area .rotation-song.active,
    header.header-container.home.sticked + .heading-player-area .rotation-song.active {
        animation: marquee 10s linear infinite;
        overflow: visible;
        width: auto;
        display: inline-block;
        top: 0px;
    }

    header:not(.home) + .heading-player-area .rotation-song.enough,
    header.header-container.home.sticked + .heading-player-area .rotation-song.enough {
        animation: none;
        overflow: hidden;
        width: auto;
    }
}

header:not(.home) + .heading-player-area .radio-playlist .played-now,
header:not(.home) + .heading-player-area .radio-playlist .played-before,
header:not(.home) + .heading-player-area .radio-playlist .played-audition,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before,
header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition {
    line-height: 1;
}

@media (min-width: 768px) and (max-width: 1540px) {
    header:not(.home) + .heading-player-area .radio-playlist .played-now .played-song,
    header:not(.home) + .heading-player-area .radio-playlist .played-before .played-song,
    header:not(.home) + .heading-player-area .radio-playlist .played-audition .played-song,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now .played-song,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before .played-song,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition .played-song {
        /*    white-space: nowrap;  */
        overflow: hidden;
        /*    width: 235px;
    max-width: 235px;  */
        width: 18.359375vw;
        max-width: 18.359375vw;
    }
}

@media (min-width: 1541px) {
    header:not(.home) + .heading-player-area .radio-playlist .played-now .played-song,
    header:not(.home) + .heading-player-area .radio-playlist .played-before .played-song,
    header:not(.home) + .heading-player-area .radio-playlist .played-audition .played-song,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-now .played-song,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-before .played-song,
    header.header-container.home.sticked + .heading-player-area .radio-playlist .played-audition .played-song {
        /* white-space: nowrap; */
        overflow: hidden;
        width: 22vw;
        max-width: 22vw;
    }
}

/* END HEADER TOP RWD CORRECTIONS */

/* NEWS SLIDER */

.news-slider {
    background: #f43f0a;
    color: #fff;
    padding: 120px 85px;
}

.news-single-slide {
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-gap: 20px;
}

.news-single-slide h3 {
    font-size: 55px;
    margin: 0;
    text-transform: none;
    width: 76%;
    width: 100%;
    line-height: 1;
    margin-bottom: 23px;
}

.slide-photo {
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
}

.slide-content {
    padding-left: 15px;
    grid-column: 2;
    grid-row: 1 / 2;
}

.slide-audition-logo {
    grid-column: 2;
    grid-row: 2/2;
    text-align: right;
    align-self: end;
}

.slide-description-days,
.slide-description-hours,
.slide-description-text {
    display: block;
    font-size: 27px;
    line-height: 1.2;
}

/* DYNAMIC BOXES EQUALIZER EFFECT */

.dynamic-boxes {
    display: grid;
    grid-template-columns: 4% 48% 48%;
    grid-gap: 0;
    position: absolute;
    width: 100%;
    background: transparent;
    top: 0;
}

.dynamic-boxes .box-1,
.dynamic-boxes .box-2,
.dynamic-boxes .box-3 {
    background: #f43f0a;
    transition: all 0.5s ease-in-out;
    /*transition-delay: 0.2s;*/
}

.dynamic-boxes.active .box-1,
.dynamic-boxes.active .box-2,
.dynamic-boxes.active .box-3 {
    transition: all 0.5s ease-in-out;
    /*transition-delay: 0.2s;*/
}

.dynamic-boxes .box-1 {
    height: 0;
    width: 100%;
    align-self: start;
}

.dynamic-boxes.active .box-1 {
    height: 0;
}

.dynamic-boxes .box-2 {
    height: 0;
    width: 100%;
    align-self: start;
}

.dynamic-boxes.active .box-2,
.dynamic-boxes.is-visible .box-2 {
    height: 55px;
    /*animation-name: boxTwoBounce;*/
    /*animation-duration: 1s;*/
    /*animation-delay: 0.01s;*/
}

.dynamic-boxes .box-3 {
    height: 0px;
    width: 100%;
    align-self: start;
}

.dynamic-boxes.active .box-3,
.dynamic-boxes.is-visible .box-3 {
    height: 110px;
    /*animation-name: boxThreeBounce;*/
    /*animation-duration: 1s;*/
    /*animation-delay: 0.01s;*/
}

@keyframes boxTwoBounce {
    0% {
        height: 0px;
    }
    50% {
        height: 55px;
    }
    100% {
        height: 0px;
    }
}

@keyframes boxThreeBounce {
    0% {
        height: 0px;
    }
    50% {
        height: 110px;
    }
    100% {
        height: 0px;
    }
}

/* SECTIONS - GLOBAL SETTINGS */

.podcasts-section,
.news-section,
.programs-section,
.people-section,
.results-section,
.schedule-section,
.info-section,
.playlist-section {
    padding: 10px 85px 110px;
}

.custom-page-section .breadcrumbs {
    padding-top: 10px;
    padding-left: 85px;
    padding-right: 85px;
}

.custom-page-section {
    padding-bottom: 110px;
}

.not-found-section {
    padding: 40px 85px 110px;
}

.home-wrap .podcasts-section,
.home-wrap .news-section,
.home-wrap .cathedras-section {
    padding: 110px 85px;
}

header:not(.home) + .heading-player-area + .main-content-container .podcasts-section {
    padding: 10px 85px 130px;
}

/* SECTION - PODCASTS */

.podcasts-section {
    background: #fff;
    background: #fe4a49;
    color: #000;
    min-height: 90vh;
    position: relative;
    overflow: hidden;
}

.podcasts-section h2,
.programs-section h2 {
    font-size: 55px;
    margin: 0;
    text-transform: none;
    width: 76%;
    line-height: 1;
    margin-bottom: 45px;
}


.podcast-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    margin: 0 auto;
    grid-gap: 50px;
    max-width: 1260px;
}

.podcast-list-container {
    max-width: 1260px;
    margin: 0 auto;
}

.podcast-item a {
    text-decoration: none;
    color: #000;
    display: block;
}

.podcast-item img {
    max-width: 100%;
    height: auto;
    aspect-ratio: 1/1;
}

@media (min-width: 1435px) {
    .podcast-item img {
        max-width: 274px;
    }
}

.podcast-description a {
    color: #fff;
}

.podcast-episode-info {
    margin-bottom: 30px;
}

.podcast-item h3 {
    font-size: 19px;
    line-height: 25px;
    margin-top: 20px;
    color: #000;
    text-decoration: none;
}

.podcast--tags,
.podcast--category,
.podcast-date {
    font-size: 14px;
    display: inline-block;
    border: 1px solid #000;
    padding: 0 5px;
    height: 24px;
    margin-right: 5px;
}

.podcast-date {
    position: relative;
    top: 0px;
}

.podcast-more-button {
    text-align: center;
    margin-top: 85px;
}

.podcast-more--url {
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
}

.podcasts-single {
    width: 1100px;
    margin: 0 auto;
    max-width: 100%;
}

.audio-playlist {
    list-style: none;
    padding: 0;
}

.audio-playlist audio {
    max-width: 100%;
}

.audio-playlist li {
    padding: 0 0 10px;
}

/* SECTION - NEWS */

.news-section {
    /*background: #fe4a49;*/
    background: #fff;
    color: #000;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.podcasts-section .dynamic-boxes .box-1,
.podcasts-section .dynamic-boxes .box-2,
.podcasts-section .dynamic-boxes .box-3 {
    background: #fff;
}

.news-section .dynamic-boxes .box-1,
.news-section .dynamic-boxes .box-2,
.news-section .dynamic-boxes .box-3 {
    /*background: #fff;*/
    background: #f43f0a;
}

.news-section h2 {
    font-size: 55px;
    margin: 0;
    text-transform: none;
    width: 76%;
    line-height: 1;
    margin-bottom: 85px;
}

.news-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    margin: 0 auto;
    max-width: 1260px;
    grid-gap: 55px 95px;

    /* SETTINGS 2019-10-29 */

    width: 1100px;
    margin: 0 auto;
    max-width: 100%;
    grid-gap: 55px 95px;
}

.news-single-element {
    width: 960px;
    margin: 0 auto;
    max-width: 100%;
}

.news-single-element a {
    color: #fff;
    color: #f43f0a;
}

.news--url,
.news-item a {
    color: #fff;
    color: #000;
    text-decoration: none;
    display: inline-block;
}

.news-item a {
    width: 100%;
}

.news--image {
    width: 100%;
}

.news--title {
    font-size: 21px;
    /*margin-top: 30px;*/
    font-weight: 500;
    margin-bottom: 0;
    line-height: 25px;
}

.news-metadata {
    width: 90%;
    line-height: 1.25;
}

.news--datetime {
    font-size: 14px;
    margin: 20px 0 10px;
    display: block;
    font-weight: 500;
}

.news--description {
}

.news--description p {
    margin: 0;
}

.news-more-button {
    text-align: center;
    margin-top: 85px;
}

.news-more--url {
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
}

/* SECTION - CATHEDRAS */

.cathedras-section {
    background: #fff;
    color: #000;
    min-height: auto;
    position: relative;
    overflow: hidden;
}

.cathedras-section .dynamic-boxes .box-1,
.cathedras-section .dynamic-boxes .box-2,
.cathedras-section .dynamic-boxes .box-3 {
    background: #fe4a49;
}

.cathedras-section h2 {
    font-size: 55px;
    font-size: 16px;
    margin: 0;
    text-transform: none;
    width: 76%;
    line-height: 1;
    margin-bottom: 35px;
}

.cathedras-section p {
    font-size: 26px;
    margin-bottom: 70px;
}

.cathedras-list {
    display: grid;
    grid-template-columns: 22% 28% 25% 25%;
}

.cathedras-list a {
    text-align: left;
}

footer {
    background: #1e00d4;
    min-height: 50vh;
    padding: 45px 85px 25px;
}

footer .container-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-columns: 28% 30.5% 30.5% 11%;
}

footer .container-grid .column.pos-1 {
    grid-area: 1 / 1 / 3 / 2;
}
footer .container-grid .column.pos-2a {
    grid-area: 1 / 2 / 2 / 3;
}
footer .container-grid .column.pos-2b {
    grid-area: 2 / 2 / 3 / 3;
}
footer .container-grid .column.pos-3 {
    grid-area: 2 / 3 / 3 / 4;
}
footer .container-grid .column.pos-4 {
    grid-area: 1 / 3 / 2 / 5;
}
footer .container-grid .column.pos-5 {
    grid-area: 3 / 1 / 4 / 2;
}
footer .container-grid .column.pos-6 {
    grid-area: 3 / 2 / 4 / 5;
}

footer h5 {
    text-transform: none;
    font-weight: 500;
    margin-bottom: 0;
}

footer p {
    font-size: 16px;
    margin-bottom: 0;
}

footer p a {
    font-weight: 500;
}

footer h4 {
    font-size: 26px;
    color: #f43f0a;
    line-height: 1.2;
    margin-bottom: 10px;
    width: 75%;
}

footer .column.pos-1 h4 {
    font-size: 18px;
}

footer .contact-data {
}

footer .address {
    margin-top: 35px;
    margin-bottom: 35px;
    font-weight: 500;
}

footer .email-contact {
    margin-bottom: 17px;
    line-height: 1.2;
}

footer .container-grid .column.pos-1 {
    color: #f43f0a;
}

footer .column.pos-3 h5 + p {
    margin-top: 17px;
    line-height: 1.2;
}

.contact-data span {
    display: block;
}

footer .container-grid .column.pos-5 {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 0 35px;
    padding-top: 90px;
}

.footer--logo img {
    margin-top: -15px;
}

.footer--copyright,
.footer--copyright p {
    align-self: start;
    color: #f43f0a;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.25;
}

header .social-menu img,
header .social-menu i {
    max-width: 20.5px;
    width: 20.5px;
    height: 20.5px;
}

footer .social-menu {
    margin: 0;
    display: inline-block;
    padding-left: 0;
    text-align: right;
    width: 100%;
    padding-right: 0;
}

.social-menu li i {
    display: inline-block;
    vertical-align: middle;
    background-size: contain;
}

.social-menu li a {
    display: inline-block;
}

footer .social-menu li {
    display: inline-block;
    width: 26px;
    padding-left: 10px;
    box-sizing: content-box;
}

header .social-menu li i {
    width: 20.5px;
    height: 20.5px;
}

footer .social-menu li i {
    width: 26px;
    height: 26px;
}

a:hover i {
    /*opacity: 0.7;*/
}

footer .icon-social-facebook {
    background: url(Assets/img/fb_icon_o.svg) center center no-repeat;
}

footer .icon-social-instagram {
    background: url(Assets/img/insta_icon_o.svg) center center no-repeat;
}

footer .icon-social-snapchat {
    background: url(Assets/img/snap_icon_o.svg) center center no-repeat;
}

footer .icon-social-twitter {
    background: url(Assets/img/twit_icon_o.svg) center center no-repeat;
}

footer .icon-social-youtube {
    background: url(Assets/img/yt_icon_o.svg) center center no-repeat;
}

footer .icon-social-spotify {
    background: url(Assets/img/sp_icon_o.svg) center center no-repeat;
}

footer .icon-social-mixcloud {
    background: url(Assets/img/mixcld_icon_o.svg) center center no-repeat;
}

header .icon-social-facebook {
    background: url(Assets/img/fb_icon.svg) center center no-repeat;
}

header .icon-social-instagram {
    background: url(Assets/img/insta_icon.svg) center center no-repeat;
}

header .icon-social-snapchat {
    background: url(Assets/img/snap_icon.svg) center center no-repeat;
}

header .icon-social-twitter {
    background: url(Assets/img/twit_icon.svg) center center no-repeat;
}

header .icon-social-youtube {
    background: url(Assets/img/yt_icon.svg) center center no-repeat;
}

header .icon-social-spotify {
    background: url(Assets/img/sp_icon.svg) center center no-repeat;
}

header .icon-social-mixcloud {
    background: url(Assets/img/mixcld_icon.svg) center center no-repeat;
}

footer .container-grid .column.pos-6 {
    align-self: start;
    font-size: 16px;
    line-height: 1;
    padding-top: 90px;
}

footer .bottom-menu {
    display: inline-block;
    margin: 0;
    padding-left: 0;
}

footer .bottom-menu li {
    display: inline-block;
    padding-right: 15px;
    font-size: 16px;
}

.content-container {
    padding: 10px 85px;
}

h2.subpage-title {
    font-size: 53px;
    line-height: 1.08;
    font-weight: 500;
    padding-bottom: 0;
    margin-bottom: 0;
}

.content-header.with-filter {
    display: grid;
    grid-template-columns: 45% 55%;
}

.navigation-filter {
    display: inline-block;
    text-transform: uppercase;
    text-align: right;
}

.navigation-filter li {
    display: inline-block;
    padding-left: 20px;
}

.programs-section a,
.people-section a {
    color: #fff;
}

.people-section .people-description a,
.programs-section .program-description a {
    color: #f43f0a;
}

.programs-section a:hover,
.people-section a:hover {
    opacity: 1;
}

.program-list-container,
.people-list-container {
    /* margin-left: 200px; */
    width: 100%;
    margin: 0 auto;
    max-width: 1280px;
}

.program-list,
.people-list {
    list-style: none;
    margin: 70px 0 0;
    letter-spacing: 0.01em;
}

.people-single-list {
    width: 1100px;
    margin: 0 auto;
    max-width: 100%;
    margin-top: 70px;
    letter-spacing: 0.01em;
}

.people-single-list strong {
    font-weight: 500;
}

.people-single-list .people-description {
    margin-top: 30px;
}

.people-single-list hr {
    border: 0;
    height: 0;
    border-top: 1px solid #fff;
    margin: 25px 0;
}

.people-single-element:last-child hr {
    display: none;
}

.program-list li h3,
.people-list li h3,
.people-single-list h3 {
    list-style: none;
    font-size: 53px;
    line-height: 1.08;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;
    margin: 0;
}

.people-single-list h3 {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: hsla(0, 0%, 100%, 0.7);
}

.program-list li,
.people-list li {
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 45% 53%;
    grid-gap: 0 2%;
}

.program-list li:not(:hover) h3,
.people-list li:not(:hover) h3 {
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0.7);
    /*transition: all 0.5s ease-in-out;*/
}

.program-list li:hover h3,
.program-list li.current h3,
.people-list li:hover h3,
.people-list li.current h3 {
    /*transition: all 0.5s ease-in-out;*/
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: #fff;
}

.program-short-description,
.people-short-description {
    position: relative;
    opacity: 0;
}

.program-list li:hover h3 + .program-short-description,
.people-list li:hover h3 + .people-short-description {
    opacity: 1;
}

.program-short-description--container,
.people-short-description--container {
    position: absolute;
}

body {
    overflow-y: scroll;
}

.main-menu a {
    text-transform: lowercase;
}

/* MENU MAIN CONTAINER */

.menu-center {
    flex: 1 1;
    position: absolute;
    width: auto;
    text-align: center;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
    z-index: 999999;
    height: 40px;
    color: #1e00d4;
    font-size: 19px;
    line-height: 40px;
    font-weight: 500;
    top: 0;
    font-family: "Atlas", sans-serif;
    border: 0;
    background: transparent;
}

.main-page-menu {
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: translateX(0px);
    height: calc(100vh - 40px);
    text-align: left;
    position: absolute;
    top: 40px;
    left: 0px;
    background: #f43f0a;
    padding: 2rem;
    transition: all 0.6s ease-in-out;
    width: 100vw;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}

.main-page-menu::-webkit-scrollbar {
    width: 10px;
}

.main-page-menu.open {
    transform: translateX(0);
}

.main-page-menu.closed {
    transform: translateY(-120%);
}

.main-page-menu-column {
    display: grid;
    grid-template-columns: 40% 50%;
    height: 100%;
    width: 70vw;
    margin: 0 auto;
    grid-gap: 80px;
}

.main-page-menu h2 {
    color: #1e00d4;
    margin-bottom: 30px;
}

.main-page-menu ul {
    list-style: none;
    font-size: 53px;
    line-height: 1.08;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;
    margin: 0;
}

.main-page-menu ul li {
    margin-bottom: 23px;
}

.main-page-menu ul li a {
    color: #1e00d4;
}

.main-page-menu ul li a:hover {
    opacity: 1;
}

.main-page-menu ul li a:not(.active) {
}

.main-page-menu ul li:not(:hover) a,
.main-page-menu ul li:not(:hover) a:not(.active) {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #1e00d4;
}

.main-page-menu ul li a.active {
    -webkit-text-fill-color: #1e00d4;
    -webkit-text-stroke-width: 0;
    -webkit-text-stroke-color: #1e00d4;
}

.menu-column--contact {
    padding-bottom: 40px;
}

.menu-column--contact h5 {
    text-transform: none;
    margin-bottom: 0;
    color: #1e00d4;
    font-size: 1em;
}

.menu-column--contact a {
    color: #fff;
}

.menu-column--contact .phone-contact p {
    margin: 0;
}

/* PROGRAM - SINGLE, PEOPLE - SINGLE */

.program-single-container,
.people-single-container {
    margin: 70px 0 0 0;
    display: grid;
    grid-template-columns: 36.2% 51.3%;
    grid-gap: 12%;
}

.program-single-container {
    margin: 0 0 4.5rem;
    display: grid;
    grid-template-columns: 350px 51.3%;
    grid-gap: 3rem;
}

.loading-container {
    margin: 70px 0 0 0;
    display: grid;
    grid-template-columns: 36.2% 51.3%;
    grid-gap: 12%;
}

.load-container {
    opacity: 1 !important;
    transition: all 0.5s ease-in-out;
}

.load-container.hidden {
    opacity: 0 !important;
    transition: all 0.5s ease-in-out;
}

.program-description h2,
.people-single-container .people-description h2 {
    font-size: 53px;
    line-height: 1.08;
    font-weight: 500;
    padding-bottom: 0;
    margin-bottom: 20px;
}

.program-description a {
    color: #fff;
}

.program-image,
.people-single-container .people-image {
    text-align: right;
    padding-top: 10px;
}

.program-image img,
.people-single-container .people-image img {
    max-width: 350px;
    width: 100%;
    height: auto;
}

@media (min-width: 768px) and (max-width: 1279px) {
    .program-image img,
    .people-single-container .people-image img {
        max-width: 100%;
        width: 100%;
    }
}

.navigation-filter a {
    font-weight: 500;
}

.navigation-filter a.active {
    color: #f43f0a;
    font-weight: 500;
}

/* PODCAST - SINGLE */

.podcasts-section .navigation-filter a.active {
    color: #fff;
}

.podcasts-single-element {
    margin: 0 0 0 0;
    display: grid;
    grid-template-columns: 36.2% 51.3%;
    grid-gap: 12%;
}

.podcasts-single-element .podcast-title {
    text-align: right;
}

.podcasts-single-element .podcast-title img {
    max-width: 300px;
}

.podcasts-section .podcasts-single-element h2 {
    width: 100%;
    margin-bottom: 40px;
    font-size: 19px;
    line-height: 1.5;
}

.podcast-source {
    margin-top: 50px;
}

/* NEWS - SINGLE */

.news-section .news-single-element h2 {
    margin-bottom: 0px;
}

.news-section .news-single-element .news--datetime {
    margin: 20px 0 20px;
}

.news--image {
    width: auto;
    max-width: 100%;
    height: auto;
    padding-bottom: 45px;
}

.news--image img {
    width: 100%;
}

/* BREADCRUMBS */

.breadcrumbs {
    font-size: 14.5px;
    margin-bottom: 18px;
}

.breadcrumbs ul {
    padding: 0;
}

.breadcrumbs ul {
    list-style: none;
    margin: 0;
}

.breadcrumbs ul li {
    display: inline-block;
}

.breadcrumbs ul li a {
    color: #fff;
}

.breadcrumbs ul li.breadcrumb-active a {
    color: #f43f0a;
}

.news-section .breadcrumbs ul li a {
    color: #000;
}

.news-section .breadcrumbs ul li.breadcrumb-active a {
    color: #fff;
    color: #f43f0a;
}

.programs-section ul li a {
}

.programs-section ul li.breadcrumb-active a {
}

.podcasts-section ul li a {
    color: #000;
}

.podcasts-section ul li.breadcrumb-active a {
    color: #f43f0a;
    color: #fff;
}

.breadcrumbs ul li:not(.home-path):before {
    content: "-";
    display: inline-block;
    padding: 0 5px;
}

/* SEARCH RESULTS */

.results--title {
    text-transform: none;
}

.results-section h2 {
    font-size: 55px;
    margin: 0;
    text-transform: uppercase;
    width: 76%;
    line-height: 1;
    margin-bottom: 85px;
}

.results-list {
    max-width: 960px;
    margin: 0 auto;
}

.results-item {
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
}

.results-section h2 .results-intro-text {
    display: block;
    font-size: 14.5px;
    margin-bottom: 18px;
    text-transform: none;
    font-weight: 400;
}

/* RAMOWKA */

.custom-page-section,
.schedule-section,
.info-section,
.playlist-section {
    overflow: hidden;
    min-height: 90vh;
    transition: all 0.5s ease-in-out;
}

@media (min-width: 768px) {
    .schedule-section {
        min-height: 110vh;
    }

    .schedule-list,
    .playlist-list {
        min-height: 465px;
    }
}

.schedule-section .navigation-filter,
.playlist-section .navigation-filter {
    margin-top: 30px;
    text-align: right;
}

.schedule-section .navigation-filter a {
    color: #fff;
}

.schedule-section .navigation-filter a.active {
    color: #f43f0a;
}

.schedule-section .content-header.with-filter {
    display: grid;
    grid-template-columns: 30% 70%;
    z-index: 1;
    position: relative;
}

.schedule-container,
.playlist-container {
    /*background: #f43f0a;*/
    z-index: 1;
    position: relative;
}

.schedule-container .subpage-title,
.playlist-container .subpage-title {
    font-size: 19px;
    font-weight: 400;
    margin-top: 10px;
}

.schedule--title a {
    color: #fff;
}

.schedule-item.active.current-day * {
    color: #1e00d4;
    font-weight: 500;
    transition: all 0.5s ease-in-out;
    transition-delay: 3s;
}

.schedule-list,
.playlist-list {
    width: 52%;
    margin: 0 auto;
    margin-top: 55px;
    max-width: 100%;
    width: 776px;
}

.player-description {
    margin: 0 auto;
    margin-top: 115px;
    max-width: 100%;
    width: 776px;
}

.player-description a {
    color: #1e00d4;
}

.player-description .player-selection {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
}

.schedule-list li,
.playlist-list li {
    list-style: none;
}

.schedule-list li a {
    padding-bottom: 3px;
    display: grid;
    grid-template-columns: 11% 30.5% 58.5%;
    color: #fff;
}

.playlist-item {
    padding-bottom: 3px;
    display: grid;
    grid-template-columns: 17% 83%;
    color: #fff;
}

.schedule-list li .category-container {
    visibility: hidden;
}

.schedule-list li .hours-container {
    display: grid;
    grid-template-columns: 31% 3% 35%;
    text-align: center;
    letter-spacing: 0.1em;
}

.schedule-list li .hours-container {
    grid-template-columns: auto;
}

.hours-container .hour-spr,
.hours-container .hour-end {
    display: none;
}

.schedule-list li p {
    margin: 0;
}

.schedule-background,
.playlist-background {
    background: #f43f0a;
    width: calc(100% - 167px);
    height: 100%;
    z-index: 0;
    position: absolute;
    right: 0;
    top: 135px;
}

.schedule-background .dynamic-boxes,
.playlist-background .dynamic-boxes {
    display: grid;
    grid-template-columns: 0% 50% 50%;
    grid-gap: 0;
    position: absolute;
    width: 235px;
    background: transparent;
    background-color: #1e00d4;
    align-self: end;
    height: 500px;
}

.schedule-background .dynamic-boxes.top-right,
.playlist-background .dynamic-boxes.top-right {
    top: 0px;
    right: 0;
}

.schedule-background .dynamic-boxes.top-right .box-1,
.playlist-background .dynamic-boxes.top-right .box-1 {
    height: 500px;
    width: 0;
    align-self: start;
    position: absolute;
    bottom: 0;
    left: 0%;
}

.schedule-background .dynamic-boxes.top-right .box-2,
.playlist-background .dynamic-boxes.top-right .box-2 {
    height: 435px;
    align-self: start;
    position: absolute;
    bottom: 0;
    width: 50%;
    left: 0;
    transition: all 0.7s ease-in-out;
}

.schedule-background .dynamic-boxes.top-right .box-3,
.playlist-background .dynamic-boxes.top-right .box-3 {
    height: 370px;
    align-self: start;
    position: absolute;
    bottom: 0;
    width: 50%;
    right: 0;
}

.schedule-background .dynamic-boxes.top-right.is-visible .box-1,
.playlist-background .dynamic-boxes.top-right.is-visible .box-1 {
    height: 500px;
}

.schedule-background .dynamic-boxes.top-right.is-visible .box-2,
.playlist-background .dynamic-boxes.top-right.is-visible .box-2 {
    height: 500px;
}

.schedule-background .dynamic-boxes.top-right.is-visible .box-3,
.playlist-background .dynamic-boxes.top-right.is-visible .box-3 {
    height: 500px;
}

.schedule-background .dynamic-boxes.bottom-left,
.playlist-background .dynamic-boxes.bottom-left {
    grid-template-columns: 50% 50% 0%;
    left: 0;
    bottom: 135px;
    top: auto;
    right: auto;
}

.schedule-background .dynamic-boxes.bottom-left .box-1,
.playlist-background .dynamic-boxes.bottom-left .box-1 {
    height: 370px;
    width: 50%;
    align-self: start;
    position: absolute;
    left: 0%;
    top: 0;
    bottom: auto;
    right: auto;
}

.schedule-background .dynamic-boxes.bottom-left .box-2,
.playlist-background .dynamic-boxes.bottom-left .box-2 {
    height: 435px;
    align-self: start;
    position: absolute;
    width: 50%;
    left: 50%;
    top: 0;
    bottom: auto;
}

.schedule-background .dynamic-boxes.bottom-left .box-3,
.playlist-background .dynamic-boxes.bottom-left .box-3 {
    height: 500px;
    align-self: start;
    position: absolute;
    bottom: 0;
    width: 0;
    right: 0;
    top: 0;
    bottom: auto;
}

.schedule-background .dynamic-boxes.bottom-left.is-visible .box-3,
.playlist-background .dynamic-boxes.bottom-left.is-visible .box-3 {
    height: 500px;
}

.schedule-background .dynamic-boxes.bottom-left.is-visible .box-2,
.playlist-background .dynamic-boxes.bottom-left.is-visible .box-2 {
    height: 500px;
}

.schedule-background .dynamic-boxes.bottom-left.is-visible .box-1,
.playlist-background .dynamic-boxes.bottom-left.is-visible .box-1 {
    height: 500px;
}

.programs-info-container {
    margin: 0 0 0 auto;
    margin-top: 55px;
    max-width: 100%;
    min-width: 730px;
    width: 62.5%;
}

.programs-info-row h4,
.programs-info-row strong {
    color: #1e00d4;
}

.programs-info-row p {
    font-size: 16px;
}

.programs-info-row h4 {
    margin-bottom: 0px;
}

.programs-info-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.programs-info-column {
    padding-right: 40px;
}

/* INFO SECTION */

.info-background {
    background: #f43f0a;
    width: calc(100vw - 170px);
    height: 100%;
    z-index: 0;
    position: absolute;
    left: 85px;
    top: 135px;
}

.info-container h3 {
    font-size: 53px;
    line-height: 1.08;
    font-weight: 500;
    padding-bottom: 0;
    margin-bottom: 0;
}

.breadcrumbs {
    z-index: 1;
    position: relative;
}

.content-header {
    z-index: 1;
    position: relative;
}

.info-section .navigation-filter {
    padding: 0;
    margin: 75px 0 0;
    position: absolute;
}

.info-section .navigation-filter li {
    display: block;
    padding-left: 30px;
    text-align: left;
    margin: 0;
    padding: 0;
}

.info-section .navigation-filter a {
    color: #fff;
}

.info-section .navigation-filter a.active {
    color: #f33f0b;
}

.info-container {
    z-index: 1;
    position: relative;
}

.info-description a {
    color: #1e00d4;
}

.info-description {
    position: relative;
    left: 420px;
    width: 50vw;
    font-weight: 400;
    padding-top: 75px;
}

.alterlator-section .info-description {
    position: static;
    width: auto;
    padding-top: 10px;
}

.alterlator-section h3 {
    font-size: 30px;
    margin-bottom: 25px;
}

.info-background .dynamic-boxes {
    display: grid;
    grid-template-columns: 33.3333% 33.33333% 33.33333%;
    grid-gap: 0;
    position: absolute;
    width: 250px;
    background: transparent;
    top: 0px;
    background-color: #1e00d4;
    left: 0;
    align-self: end;
    height: 500px;
}

.info-background .dynamic-boxes .box-1 {
    height: 60px;
    width: 33.33333333333333%;
    align-self: start;
    position: absolute;
    bottom: 0;
    left: 0%;
}

.info-background .dynamic-boxes .box-2 {
    height: 182px;
    width: 100%;
    align-self: start;
    position: absolute;
    bottom: 0;
    width: 33.33333333333333%;
    left: 33.33333333333333%;
    transition: all 0.7s ease-in-out;
}

.info-background .dynamic-boxes .box-3 {
    height: 305px;
    width: 100%;
    align-self: start;
    position: absolute;
    bottom: 0;
    width: 33.33333333333333%;
    right: 0;
}

.info-background .dynamic-boxes.is-visible .box-1 {
    height: 75px;
}

.info-background .dynamic-boxes.is-visible .box-2 {
    height: 55px;
}

.info-background .dynamic-boxes.is-visible .box-3 {
    height: 110px;
}

.info-background .dynamic-boxes.bottom-right {
    right: 0;
    bottom: 135px;
    top: auto;
    left: auto;
}

.info-background .dynamic-boxes.bottom-right .box-1 {
    height: 380px;
    width: 33.33333333333333%;
    align-self: start;
    position: absolute;
    left: 0%;
    top: 0;
    bottom: auto;
    right: auto;
}

.info-background .dynamic-boxes.bottom-right .box-2 {
    height: 260px;
    align-self: start;
    position: absolute;
    bottom: 0;
    width: 33.33333333333333%;
    left: 33.33333333333333%;
    top: 0;
    bottom: auto;
}

.info-background .dynamic-boxes.bottom-right .box-3 {
    height: 65px;
    align-self: start;
    position: absolute;
    bottom: 0;
    width: 33.33333333333333%;
    right: 0;
    top: 0;
    bottom: auto;
}

.info-background .dynamic-boxes.bottom-right.is-visible .box-3 {
    height: 80px;
}

.info-background .dynamic-boxes.bottom-right.is-visible .box-2 {
    height: 55px;
}

.info-background .dynamic-boxes.bottom-right.is-visible .box-1 {
    height: 110px;
}

/* TEST ANIMATION */

.news-section,
.podcasts-section,
.programs-section,
.header-container.home + .heading-player-area,
.results-section,
.schedule-section,
.playlist-section,
.people-section,
.info-section,
.info-single-container .subpage-title,
.contact-section {
    animation-name: showIn;
    animation-duration: 0.5s;
    position: relative;
    opacity: 0;
    animation-fill-mode: forwards;
}

.played-song {
    animation-name: showIn;
    animation-duration: 1.2s;
    position: relative;
    opacity: 0;
    animation-fill-mode: forwards;
}

.news-item,
.podcast-item,
.program-list li,
.people-list li,
.schedule-list li,
.program-single-container,
.podcasts-single-element,
.results-list .results-item,
.news-single-element,
.custom-page-single-container,
.info-description,
.playlist-list .playlist-item,
.contact-section .menu-column--contact .contact-data {
    animation-name: mymove;
    animation-duration: 0.5s;
    position: relative;
    top: 0px;
    transform: translate(0, 100px);
    opacity: 0;
    animation-fill-mode: forwards;
}

.news-item:nth-child(2n + 1),
.podcast-item:nth-child(4n + 1) {
    animation-delay: 0.2s;
    /*background: #f00;*/
}

.news-item:nth-child(2n + 2),
.podcast-item:nth-child(4n + 2) {
    animation-delay: 0.1s;
}

.podcast-item:nth-child(4n + 3) {
    animation-delay: 0.3s;
}

.podcast-item:nth-child(4n + 4) {
    animation-delay: 0.4s;
}

@keyframes mymove {
    0% {
        transform: translate(0, 100px);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0px);
        opacity: 1;
    }
}

@keyframes showIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* HEADER LINE */

.header-container:not(.home) + .heading-player-area,
header.header-container.home.sticked + .heading-player-area {
    border-bottom: 1px solid #f33f0b;
}

.scroll {
    opacity: 0.75;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 2px;
    border: none;
    background-color: #f43f0a;
    background-color: transparent;
    cursor: pointer;
    z-index: 9;
}

.scroll:hover {
    opacity: 1;
    outline: none;
}

.scroll:focus {
    outline: 0;
}

.arrow-up {
    color: #1e00d4;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 2px;
    margin-left: 1px;
    font-size: 27px;
    line-height: 40px;
    transform: translate(-50%, -50%);
    font-family: "Atlas", sans-serif;
    background: transparent url(Assets/img/back_top.svg) center center no-repeat;
    /* filter: invert(1); */
    transform: translate(-50%, -50%);
    width: 42px;
    height: 22px;
}

.scroll {
    opacity: 1;
    width: 42px;
    height: 22px;
    bottom: 15px;
    right: 15px;
}

/* PEOPLE */

.people-list li {
    padding-bottom: 20px;
    display: block;
    grid-template-columns: 45% 53%;
    grid-gap: 0 2%;
}

.people-list .people-item {
    width: 100%;
}

.people-list .people--title {
    width: auto;
    display: inline;
}

.people-list .people-short-description {
    display: inline-block;
    width: 100px;
}

.people-list .people-short-description img {
    position: absolute;
    display: block;
    width: 100px;
    height: 100px;
    bottom: 0;
    margin-left: 30px;
}

.people-list .people-short-description--container {
    position: absolute;
    margin-left: 150px;
    width: 200px;
    top: -75px;
    background-color: #1e00d4;
}

/* DATE PICKER */

.react-datepicker__input-container input {
    cursor: pointer;
}

.select-day-container {
    position: absolute;
    right: 245px;
    top: -95px;
}

.select-day-heading {
    display: inline-block;
    position: relative;
    left: 100px;
    text-transform: uppercase;
}

.react-datepicker-wrapper {
}

.react-datepicker:before {
    content: "";
    display: block;
    height: 21px;
    width: 331px;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    background: #1e00d4 url(Assets/img/calendar_line.svg) center center no-repeat;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: auto;
    left: 97px;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 30px;
}

.react-datepicker__month-container {
    min-width: 330px;
}

.react-datepicker__input-container input {
    width: auto;
    max-width: 135px;
    color: #fff;
    background: transparent;
    border: 0;
    box-shadow: none;
    font-size: 19px;
    color: #f33f0b;
    font-weight: 500;
    font-family: "Atlas", sans-serif;
    text-align: right;
}

.react-datepicker__input-container input:focus {
    outline: 0 !important;
}

.react-datepicker {
    font-size: 14px;
    background-color: #1e00d4;
    color: #fff;
    border: 0px solid #1e00d4;
    border-radius: 0;
    display: inline-block;
    position: relative;
    font-weight: 500;
    font-family: "Atlas", sans-serif;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.126rem 0.216rem;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
}

.react-datepicker__header {
    text-align: center;
    background-color: #1e00d4;
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
    position: relative;
}

.react-datepicker__month {
    margin: 0 0.4rem 1rem;
    text-align: center;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    margin: 25px 0 17px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    border-radius: 0;
    background-color: #f43f0a;
    color: #fff;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    border-radius: 0;
    background-color: transparent;
    color: #f43f0a;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
    color: rgba(255, 255, 255, 0.3) !important;
}

.react-datepicker__day--outside-month {
    color: transparent !important;
}

.react-datepicker__day--outside-month:hover {
    color: rgba(255, 255, 255, 0.3) !important;
}

.react-datepicker__navigation {
    top: 37px;
}

.react-datepicker__navigation--previous {
    left: 40px;
    border-right-color: #ccc;
    background: #1e00d4 url(Assets/img/arrow_l.svg) center center no-repeat;
    background-size: 17px 15px;
    width: 17px;
    height: 15px;
    border: 0;
}

.react-datepicker__navigation--next {
    right: 40px;
    border-left-color: #ccc;
    background: #1e00d4 url(Assets/img/arrow_r.svg) center center no-repeat;
    background-size: 17px 15px;
    width: 17px;
    height: 15px;
    border: 0;
}

.react-datepicker-popper {
    z-index: 99999999999;
}

.pagination {
    list-style: none;
    width: 100%;
    margin: 0 auto;
    max-width: 1260px;
    padding: 30px 0;
}

.programs-section .pagination li,
.programs-section .pagination li.active,
.people-section .pagination li,
.people-section .pagination li.active,
.news-section .pagination li,
.news-section .pagination li.active,
.podcasts-section .pagination li,
.podcasts .pagination li.active {
    cursor: pointer;
    display: inline-block;
    list-style: none;
    font-size: 53px;
    line-height: 1.08;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.01em;
    margin: 0;
    color: #f43f0a;
    padding: 0 10px 0 0;
}

.podcasts-section .pagination li,
.podcasts .pagination li.active {
    color: #000;
}

.programs-section .pagination li a,
.people-section .pagination li a,
.news-section .pagination li a {
    color: #f43f0a;
}

.podcasts-section .pagination li a {
    color: #000;
}

.pagination li:not(:hover) {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #f43f0a;
    /* transition: all 0.5s ease-in-out; */
}

.pagination li.active {
    -webkit-text-fill-color: #f43f0a;
}

.news-section .pagination li.page_list_separator {
    -webkit-text-fill-color: #f43f0a !important;
}

.podcasts-section .pagination li.page_list_separator {
    -webkit-text-fill-color: #000 !important;
}

.podcasts-section .pagination li:not(:hover) {
    -webkit-text-stroke-color: #000;
    /* transition: all 0.5s ease-in-out; */
}

.podcasts-section .pagination li.active {
    -webkit-text-fill-color: #000;
}

.page-next-button a,
.slick-next:before {
    display: block;
    background: url(Assets/img/arrow_slide_r.svg) center center no-repeat;
    background-size: 20px 40px;
    width: 20px;
    height: 40px;
    border: 0;
    position: relative;
    top: 12px;
    left: 12px;
    transition: all 0.5s ease-in-out;
}

.page-prev-button a,
.slick-prev:before {
    display: block;
    background: url(Assets/img/arrow_slide_l.svg) center center no-repeat;
    background-size: 20px 40px;
    width: 20px;
    height: 40px;
    border: 0;
    position: relative;
    top: 12px;
    left: 0px;
    transition: all 0.5s ease-in-out;
}

.slick-prev:before,
.slick-next:before {
    content: "";
    top: -10px;
    left: 0px;
    transition: all 0.5s ease-in-out;
    opacity: 1;
}

.slick-dots li button:before {
    font-size: 15px;
}

.slick-prev:hover:before,
.slick-next:hover:before {
    opacity: 0.75;
}

.page-prev-button:hover,
.page-next-button:hover {
    opacity: 0.7;
}

.page-prev-button span,
.page-next-button span {
    display: block;
    text-indent: -9999px;
}

.banner-container {
    position: relative;
    top: -42.5px;
    display: block;
    max-height: 195px;
    max-width: 100%;
}

.banner-container {
    position: relative;
    top: -42.5px;
    display: block;
    max-height: 195px;
    max-width: 100%;
}

.banner-container a {
    transition: all 0.5s ease-in-out;
}

@media (min-width: 1220px) {
    header.home + .heading-player-area .logo-area {
        max-width: 1210px;
    }

    header.home.sticked + .heading-player-area .logo-area {
        max-width: none;
    }
}

/* SLIDER */

.home-slider-section {
    background: #f43f0a;
    color: #fff;
    padding: 120px 85px;
    max-width: 100%;
    overflow: hidden;
    /* min-height: 800px; */
}

.slide-title a {
    color: #fff;
}

.home-slider-section .slide-photo img {
    max-width: 840px;
    width: 100%;
    height: auto;
}

.slick-slide .slide-audition-logo img {
    display: inline-block;
}

.home-slider-section .news-single-slide {
    display: grid !important;
}

.slick-next {
    right: -59px;
    z-index: 9;
}

.slick-prev {
    left: -59px;
    z-index: 9;
}

.slick-dots {
    bottom: -60px;
}

/* CONTACT FALLBACK */

.contact-section,
.alterlator-section {
    padding: 10px 85px 110px;
    background-color: #f43f0a;
    min-height: 80vh;
}

.contact-section ul li.home-path a {
    color: #1e00d4;
}

.contact-section ul li.breadcrumb-active a {
    color: #fff;
}

.contact-section h2.subpage-title {
    color: #1e00d4;
    margin-bottom: 30px;
}

.alterlator-section h2.subpage-title {
    color: #ffffff;
    margin-bottom: 30px;
}

@media (min-width: 960px) {
    .contact-section .menu-column--contact {
        max-width: 50%;
    }

    html.is-win.locked .top-menu--regular .social-menu {
        margin-right: 17px;
    }

    html.is-win.locked header.home + .heading-player-area .logo-area {
        margin-right: 17px;
    }

    html.is-win.locked .main-page-menu-column {
        position: relative;
        right: 8.5px;
    }

    html.is-win.locked header:not(.home) + .heading-player-area .radio-playlist,
    html.is-win.locked header.header-container.home.sticked + .heading-player-area .radio-playlist {
        margin-right: 17px;
    }
}

@media (min-width: 768px) and (orientation: landscape) {
    .mobile-wrap .menu-container.top-menu .social-menu {
        min-width: none;
        max-width: none;
    }

    .mobile-wrap .social-menu {
        padding-right: 0;
    }

    .mobile-wrap .mobile-items-wrapper {
        grid-column: 1 / span 2;
    }

    .menu-container.top-menu .mobile-wrap .social-menu {
        min-width: 240px;
        max-width: none;
        line-height: 32px;
    }

    .main-page-menu-column.mobile-wrap {
        display: grid;
        grid-template-columns: 100% 40% 50%;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        grid-gap: 80px;
        grid-template-columns: 50% 50%;
        /* grid-template-rows: 100% 50% 50%; */
        grid-gap: 1em;
        margin-top: -2rem;
    }

    .main-page-menu .mobile-wrap ul.social-menu li {
        margin-bottom: 0px;
        display: inline-block;
        padding-left: 6px;
    }

    .main-page-menu .mobile-wrap ul,
    .mobile-wrap h2.subpage-title {
        font-size: 35px;
    }
}

@media (min-width: 1160px) {
    .program-list-container,
    .people-list-container {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
    }

    .people-list,
    .program-list {
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .menu-container.top-menu .social-menu {
        min-width: 240px;
        max-width: 240px;
    }

    .menu-group-right {
        min-height: 40px;
        max-height: 40px;
    }

    .search-bar-container button.active + .search-input-area {
        width: 195px;
    }

    .search-bar-container.search-bar-podcasts button + .search-input-area {
        width: calc(100% - 40px);
    }

    .search-bar input[type="text"] {
        width: 175px;
    }

    .search-bar-container.search-bar-podcasts {
        width: 320px;
    }

    .search-bar .search-bar-podcasts input[type="text"] {
        width: calc(100% - 20px);
    }
}

@media (max-width: 767px) {

    .search-bar-container.search-bar-podcasts {
        width: 100%;
    }

    .search-bar .search-bar-container.search-bar-podcasts input[type="text"] {
        width: calc(100% - 20px);
    }

    .search-bar-container.search-bar-podcasts button + .search-input-area {
        width: calc(100% - 40px);
    }
}

@media (min-width: 768px) and (max-width: 1366px) {
    .news-single-slide h3 {
        font-size: 45px;
        width: 100%;
    }

    .podcasts-section h2,
    .programs-section h2,
    .news-section h2,
    .cathedras-section h2,
    .results-section h2,
    .programs-section .pagination li,
    .programs-section .pagination li.active,
    .people-section .pagination li,
    .people-section .pagination li.active,
    .news-section .pagination li,
    .news-section .pagination li.active,
    .podcasts-section .pagination li,
    .podcasts .pagination li.active,
    h2.subpage-title,
    .program-list li h3,
    .people-list li h3,
    .people-single-list h3,
    .main-page-menu ul,
    .program-description h2,
    .people-single-container .people-description h2,
    .info-container h3 {
        font-size: 45px;
    }

    .cathedras-section h2 {
        font-size: 16px;
    }

    .alterlator-section h3 {
        font-size: 30px;
        margin-bottom: 30px;
    }
}

.player-container {
    position: relative;
    text-align: left;
}

.player-wrapper {
    width: auto;
    display: inline-block;
}

/* VERSION 1 */

/*.volume {
    position: relative;
    cursor: pointer;
    width: 130px;
    height: 20px;
    float: left;
    margin-top: 0px;
    margin-right: 0px;
    background-color: #000;
}
.volumeBar {
    display:block;
    height:20px;
    position:relative;
    top:0;
    left:0;
    background-color:#f43f0a;
    z-index:10;
    width: 90%;
}*/

/*.player-container .volume-container {
    background: #1e00d4;
    padding: 0px 10px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 23px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
}


.player-container:hover .volume-container {
    background: #1e00d4;
    padding: 15px 10px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 23px;
    opacity: 1;
}*/

.volume-container.hidden {
    display: none;
}

/* VERSION 2 */

.player-container .player-wrapper .volume-container {
    padding: 0px 0px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 75px;
    opacity: 1;
    width: 0px;
    z-index: 9;
}

.player-container .player-wrapper:hover .volume-container {
    padding: 0px 0px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 1;
    width: 120px;
}

.player-container .player-wrapper .volume-background {
    transition: all 0.5s ease-in-out;
    background: #1e00d4;
    padding: 0px 0px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0px;
    opacity: 1;
    width: 120px;
    transition-delay: 0.5s;
    height: 0px;
}

.player-container .player-wrapper:hover .volume-background {
    transition: all 0.5s ease-in-out;
    padding: 45px 10px;
    transition-delay: 0s;
}

.player-container .player-wrapper .volume-bar-container {
    width: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    transition-delay: 0.2s;
    opacity: 0;
}

.player-container .player-wrapper:hover .volume-bar-container {
    width: 95px;
    transition: all 0.5s ease-in-out;
    transition-delay: 0.2s;
    opacity: 1;
}

.volumeBar {
    display: block;
    height: 50px;
    position: relative;
    top: 0;
    left: 0;
    background-color: none;
    z-index: 100;
    width: 100px;
    cursor: pointer;
}

.volume-icon {
    background: url(Assets/img/volume_orange.svg) left 6px no-repeat;
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 30px;
    z-index: 9;
    position: absolute;
    top: 0;
}

.volume {
    /*background-color:#888;*/
    position: absolute;
    clip: rect(0px, 90px, 100px, 0px);
    width: 100px;
    height: 0;
    border-style: solid;
    border-width: 0 0 50px 100px;
    border-color: transparent transparent #f43f0a transparent;
    line-height: 0px;
}

.volumebkg {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 50px 100px;
    border-color: transparent transparent #000 transparent;
    line-height: 0px;
}

header:not(.home) + .heading-player-area .player-container .player-wrapper .volume-background,
header.sticked + .heading-player-area .player-container .player-wrapper .volume-background {
    display: none;
}

header:not(.home) + .heading-player-area .player-container .player-wrapper .volume-background-min,
header.sticked + .heading-player-area .player-container .player-wrapper .volume-background-min {
    background: #1e00d4;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0px;
    transition: all 0.6s ease-in-out;
    padding: 50px 0px;
    transition-delay: 0.2s;
    box-shadow: 0px 0px 0px 0px #1902a3;
    width: 0;
    opacity: 1;
    height: 0px;
}

header:not(.home) + .heading-player-area .player-container .player-wrapper:hover .volume-background-min,
header.sticked + .heading-player-area .player-container .player-wrapper:hover .volume-background-min {
    transition: all 0.6s ease-in-out;
    padding: 50px 10px;
    transition-delay: 0s;
    box-shadow: 7px -5px 0px 5px #1902a3;
    width: 130px;
    opacity: 1;
}

@media (min-width: 768px) {
    .main-player-button {
        margin-right: 0px;
    }

    .player-container {
        margin-right: 25px;
    }
}

/* ALTERLATOR */
footer {
    position: relative;
}

.vote-list {
    margin-top: 30px;
}

[type="radio"] {
    margin-right: 15px;
}

.vote-list.done [type="radio"] {
    opacity: 0;
}

fieldset {
    border: 1px solid rgb(255 255 255 / 0.25);
    padding: 15px 25px;
    margin-bottom: 20px;
}

fieldset p {
    margin-bottom: 5px;
}

legend {
    text-transform: uppercase;
    font-weight: bold;
    color: #1e00d4;
}

#submit-vote, .btn-modal, .btn-go-back, .btn-all-podcasts {
    /* position: fixed;
    right: 0px;
    bottom: 21vh; */
    border: 1px solid #1e00d4;
    padding: 15px 25px;
    background-color: #1e00d4;
    color: #f43f0a;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Atlas", sans-serif;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.btn-all-podcasts {
    align-self: flex-start;
    line-height: 1;
    margin: 0 0 0 auto;
    order: 2;
    color: white;
}

.btn-go-back {
    padding: 10.5px 25px;
    align-self: flex-start;
}


#submit-info {
    position: fixed;
    right: 0;
    top: 25vh;
    background: #fff;
    padding: 20px;
    background-color: white;
    background-color: white;
    color: #f43f0a;
    text-transform: uppercase;
    font-weight: 500;
    width: 320px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}

#submit-info.success,
#submit-info.failed,
#submit-info.disabled {
    opacity: 1;
}

#submit-info.success {
    opacity: 0;
}

#submit-info.success.done {
    opacity: 1;
    animation-name: disappear;
    animation-fill-mode: forwards;
    animation-delay: 5s;
    animation-duration: 1s;
}

#submit-vote:hover {
    opacity: 1;
}

#submit-vote:active,
#submit-vote:focus {
    outline: none;
}

#submit-vote.done {
    animation-name: disappear;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    /* display: none; */
}

@keyframes disappear {
    0% {
        opacity: 1;
    }
    95% {
        opacity: 0;
        z-index: 0;
        right: 0;
    }
    100% {
        opacity: 0;
        position: fixed;
        right: -100vw;
    }
}



/* SUMMARY VOTE */

.summary-input {
    display: none;
}

.summary.columns-3 {
    display: grid;
    grid-template-columns: 1fr 80px 125px;
    grid-gap: 30px;
    align-items: center;
}

.summary.columns-3 #submit-vote {
    max-width: 126px;
    display: block;
}

.summary.columns-3 audio {
    display: inline-block;
    max-width: 80px;
}


/* MODAL */

.modal:target:before, .modal.hidden:before {
    display: none;
}
.modal:before {
    content:"";
    display: block;
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
.modal .modal-dialog {
    background: #fefefe;
    border: #aaa solid 2px;
    border-radius: 0px;
    position: fixed;
    left: 50%;
    z-index: 11;
    width: 100%;
    max-width: 420px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    top: 25%;
}
.modal:target .modal-dialog, .modal.hidden .modal-dialog {
    top: -100%;
    -webkit-transform: translate(0, -500%);
    -ms-transform: translate(0, -500%);
    transform: translate(0, -500%);
}
.modal-body {
    padding: 20px;
}
.modal-header, .modal-footer {
    padding: 10px 20px;
}
.modal-header {
    border-bottom: #eeeeee solid 1px;
}
.news-section .modal-header h2 {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.modal-footer {
    text-align: center;
    padding: 0px 20px;
    margin-bottom: 20px;
}

.btn-modal {
    border: 1px solid #f43f0a;
    padding: 15px 25px;
    background-color: #f43f0a;
    color: #fff;
    display: inline-block;
    margin: 0 auto;
}

.btn-modal.decline {
    background: #aaa;
    border: 1px solid #aaa;
}

.rsme-instagram-embed {
    width: 100%;
    max-width: 480px;
}