@font-face {
    font-family: "Atlas";
    src: url("./Assets/fonts/AtlasGrotesk-Regular-Web.woff") format('woff');
    font-display: swap;
}


@font-face {
    font-family: "Atlas";
    src: url("./Assets/fonts/AtlasGrotesk-Medium-Web.woff") format('woff');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Atlas";
    src: url("./Assets/fonts/AtlasGrotesk-Medium-Web.woff") format('woff');
    font-weight: 700;
    font-display: swap;
}
